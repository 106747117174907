import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {PlayerUtils} from "data/utils/player";
import {IStatPlayer} from "data/types/matchStats";
import {orderBy} from "lodash";
import type {IHighlightStore} from "data/stores/highlight/highlight.store";
import {LIVE_CHANGE_CLASS} from "data/constants";

export interface IPlayersController extends ViewController {
	get isLoading(): boolean;

	get passingPlayersFirst(): IStatPlayer[];

	get rushingPlayersFirst(): IStatPlayer[];

	get receivingPlayersFirst(): IStatPlayer[];

	get defensivePlayersFirst(): IStatPlayer[];
	get passingPlayersSecond(): IStatPlayer[];
	get rushingPlayersSecond(): IStatPlayer[];
	get receivingPlayersSecond(): IStatPlayer[];
	get defensivePlayersSecond(): IStatPlayer[];

	get fieldGoalsPlayersFirst(): IStatPlayer[];
	get fieldGoalsPlayersSecond(): IStatPlayer[];
	get kickOffPlayersSecond(): IStatPlayer[];
	get kickOffPlayersFirst(): IStatPlayer[];
	get puntsPlayersSecond(): IStatPlayer[];
	get puntsPlayersFirst(): IStatPlayer[];
	get puntsReturnsPlayersSecond(): IStatPlayer[];
	get puntsReturnsPlayersFirst(): IStatPlayer[];
	get kickOffReturnsPlayersSecond(): IStatPlayer[];
	get kickOffReturnsPlayersFirst(): IStatPlayer[];
	get fieldGoalsMissedReturnsPlayersSecond(): IStatPlayer[];
	get fieldGoalsMissedReturnsPlayersFirst(): IStatPlayer[];
	get teamLossesPlayersSecond(): IStatPlayer[];
	get teamLossesPlayersFirst(): IStatPlayer[];
	get isRoundWithAllStats(): boolean;

	takeStats(keys: (keyof IStatPlayer["stats"])[]): ((player: IStatPlayer) => number)[];

	isHighlightedPlayerStatClass(id: number, key: string): string;
}

@injectable()
export class PlayersController implements IPlayersController {
	constructor(
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.HighlightStore) private _highlightStore: IHighlightStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._matchStatsStore.isLoading;
	}

	get passingPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.passingPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["passesAttempted", "passesSucceededYards"]),
			["desc", "desc"]
		);
	}

	get rushingPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.rushingPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["rushes", "rushingYards"]),
			["desc", "desc"]
		);
	}

	get receivingPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.receivingPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["receptions", "receptionsYards"]),
			["desc", "desc"]
		);
	}

	get defensivePlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return this._matchStatsStore.defensivePlayers.filter((it) =>
			PlayerUtils.isFirstSquad(it, tournament)
		);
	}

	get passingPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.passingPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["passesAttempted", "passesSucceededYards"]),
			["desc", "desc"]
		);
	}

	get rushingPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.rushingPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["rushes", "rushingYards"]),
			["desc", "desc"]
		);
	}

	get receivingPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.receivingPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["receptions", "receptionsYards"]),
			["desc", "desc"]
		);
	}

	get defensivePlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return this._matchStatsStore.defensivePlayers.filter((it) =>
			PlayerUtils.isSecondSquad(it, tournament)
		);
	}

	get fieldGoalsPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.fieldGoalsPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["fieldGoalsAttempted", "fieldGoalsSucceeded"]),
			["desc", "desc"]
		);
	}

	get fieldGoalsPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.fieldGoalsPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["fieldGoalsAttempted", "fieldGoalsSucceeded"]),
			["desc", "desc"]
		);
	}

	get kickOffPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.kickOffPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["kickoffsYards", "kickoffs"]),
			["desc", "desc"]
		);
	}

	get kickOffPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.kickOffPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["kickoffsYards", "kickoffs"]),
			["desc", "desc"]
		);
	}

	get puntsPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.puntsPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["puntingYards", "punts"]),
			["desc", "desc"]
		);
	}

	get puntsPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.puntsPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["puntingYards", "punts"]),
			["desc", "desc"]
		);
	}

	get puntsReturnsPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.puntsReturnsPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["puntingReturnsYards", "puntsReturns"]),
			["desc", "desc"]
		);
	}

	get puntsReturnsPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.puntsReturnsPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["puntingReturnsYards", "puntsReturns"]),
			["desc", "desc"]
		);
	}

	get kickOffReturnsPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.kickOffReturnsPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["kickoffsReturnsYards", "kickoffsReturns"]),
			["desc", "desc"]
		);
	}

	get kickOffReturnsPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.kickOffReturnsPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["kickoffsReturnsYards", "kickoffsReturns"]),
			["desc", "desc"]
		);
	}

	get fieldGoalsMissedReturnsPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.fieldGoalsMissedReturnsPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["fieldGoalsMissedReturnsYards", "fieldGoalsMissedReturns"]),
			["desc", "desc"]
		);
	}

	get fieldGoalsMissedReturnsPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.fieldGoalsMissedReturnsPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["fieldGoalsMissedReturnsYards", "fieldGoalsMissedReturns"]),
			["desc", "desc"]
		);
	}

	get teamLossesPlayersSecond() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.teamLossesPlayers.filter((it) =>
				PlayerUtils.isSecondSquad(it, tournament)
			),
			this.takeStats(["losses", "lossesYards"]),
			["desc", "desc"]
		);
	}

	get teamLossesPlayersFirst() {
		const tournament = this._roundsStore.selectedTournament;
		return orderBy(
			this._matchStatsStore.teamLossesPlayers.filter((it) =>
				PlayerUtils.isFirstSquad(it, tournament)
			),
			this.takeStats(["losses", "lossesYards"]),
			["desc", "desc"]
		);
	}

	get isRoundWithAllStats(): boolean {
		return !this._roundsStore.isRoundWithPrevStats;
	}

	takeStats(keys: (keyof IStatPlayer["stats"])[]) {
		return keys.map((key) => {
			return (player: IStatPlayer) => {
				return player.stats[key] || 0;
			};
		});
	}

	isHighlightedPlayerStatClass(id: number, key: string) {
		const playerIndex = this._matchStatsStore.players.findIndex((it) => it.id === id);

		const keySearch = `players.${playerIndex}.stats.${key}`;

		return this._highlightStore.isHighlight(keySearch) ? LIVE_CHANGE_CLASS : "";
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
