import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {IDrive} from "data/types/matchStats";
import {LIVE_CHANGE_CLASS} from "data/constants";
import type {IHighlightStore} from "data/stores/highlight/highlight.store";
import {WithRowIndex} from "data/types/generics";
import {SideOfPitch} from "data/enums";
import {SquadUtils} from "data/utils/squad";
import {PlayerUtils} from "data/utils/player";

export interface IDriveChartTableController extends ViewController {
	get isLoading(): boolean;

	get drivesHome(): WithRowIndex<IDrive>[];
	get drivesAway(): WithRowIndex<IDrive>[];
	getSquadFirstLetter(sideOfPitch: SideOfPitch): string;
	highlightTableRowClass(rowIndex: number): string;

	buildQB(playerFeedId: number): string;
}

@injectable()
export class DriveChartTableController implements IDriveChartTableController {
	constructor(
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.HighlightStore) private _highlightStore: IHighlightStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._matchStatsStore.isLoading;
	}

	get drivesHome() {
		const tournament = this._roundsStore.selectedTournament;
		const homeSquadId = tournament?.homeSquad.id;
		return this._matchStatsStore.drives.filter((it) => this.driveFilter(it, homeSquadId));
	}

	get drivesAway() {
		const tournament = this._roundsStore.selectedTournament;
		const awaySquadId = tournament?.awaySquad.id;
		return this._matchStatsStore.drives.filter((it) => this.driveFilter(it, awaySquadId));
	}

	getSquadFirstLetter(sideOfPitch: SideOfPitch) {
		const tournament = this._roundsStore.selectedTournament;

		const squadAbbrs = {
			home: SquadUtils.getAbbreviation(
				this._squadsStore.getSquadById(tournament?.homeSquad.id)
			),
			away: SquadUtils.getAbbreviation(
				this._squadsStore.getSquadById(tournament?.awaySquad.id)
			),
		};

		const sideOfPitchSquad =
			sideOfPitch === SideOfPitch.Home ? squadAbbrs.home : squadAbbrs.away;

		return sideOfPitch?.length && sideOfPitchSquad?.length ? sideOfPitchSquad[0] : "";
	}

	private driveFilter(it: IDrive, squadId?: number) {
		return squadId === it.teamInPossession && !it.isKickoff;
	}

	highlightTableRowClass(rowIndex: number) {
		const keySearch = `drives.[${rowIndex}]`;

		return this._highlightStore.isHighlight(keySearch) ? LIVE_CHANGE_CLASS : "";
	}

	buildQB(playerFeedId: number): string {
		const feedId = playerFeedId.toString();
		const player = this._matchStatsStore.players.find((it) => it.feedId === feedId);

		if (!player) {
			return "-";
		}

		return PlayerUtils.buildPLayerName(player);
	}

	init(param: void) {
		return;
	}

	dispose() {
		return;
	}
}
