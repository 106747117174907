import {IAxiosApiError, IAxiosErrorMessageObject} from "data/types/api";
import {AxiosError} from "axios";
import {Empty, NullOrEmpty} from "data/types/generics";
import {SQUAD_IMAGE_URL} from "data/constants";

export function getErrorMessageFromAxiosResponse(event: AxiosError<IAxiosApiError, unknown>) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

export function getDashedValue<T>(value: NullOrEmpty<T>, replacer = "-"): string {
	if (value === null || value === undefined) {
		return replacer;
	}
	return String(value);
}

export function getIsValueEmptyOrNull<T>(value: NullOrEmpty<T>): boolean {
	return value === undefined || value === null;
}

export function getImageFromBackend(path: Empty<string>): string {
	if (!path) {
		return "";
	}
	return `${SQUAD_IMAGE_URL}${path}.svg`;
}

export const convertToPercents = (value: number, fraction?: number) => {
	if (isNaN(value)) {
		return "0.0%";
	}
	if (value === 1) {
		return "100.0%";
	}
	return `${(value * 100).toFixed(fraction ?? 1)}%`;
};

export const toFixed = (value?: number) => {
	return (value || 0).toFixed(1);
};

export const toTimeFormat = (timeInSeconds: number = 0) => {
	if (!timeInSeconds) {
		return "00:00";
	}

	const minutes = timeInSeconds / 60;
	const fixedMinutes = Math.floor(minutes).toFixed(0);
	const seconds = timeInSeconds % 60;
	return `${fixedMinutes}:${seconds < 10 ? "0" : ""}${seconds.toFixed(0)}`;
};

export const buildOrdinalNumber = (value: number) => {
	const pr = new Intl.PluralRules("en-US", {type: "ordinal"});

	const suffixes = new Map([
		["one", "st"],
		["two", "nd"],
		["few", "rd"],
		["other", "th"],
	]);

	const rule = pr.select(value);
	const suffix = suffixes.get(rule) ?? "";

	return `${value}${suffix}`;
};
