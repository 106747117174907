export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum Language {
	English = "en-US",
}

export enum UserStatus {
	Approved = "approved",
	Denied = "denied",
	WaitingForApprove = "waiting_for_approve",
	Disabled = "disabled",
	Suspended = "suspended",
}

export enum ModalType {
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	ERROR,
	SUCCESS,
}

export enum SeasonFilter {
	PreSeason = "pre",
	RegularSeason = "reg",
	PostSeason = "post",
}

export enum TournamentStatus {
	Complete = "complete",
	Playing = "playing",
	Scheduled = "scheduled",
}

export enum SideOfPitch {
	Away = "A",
	Home = "H",
	Empty = "",
}

export enum FixtureTabs {
	GameCast = "GAMECAST",
	Team = "TEAM",
	Offence = "OFFENCE",
	KickReturns = "KICK/RETURNS",
	Defence = "DEFENCE",
	PlayList = "PLAY LIST",
	Scoring = "SCORING",
	DriveChart = "DRIVE CHART",
}

export enum Possession {
	HOME = "Home",
	AWAY = "Away",
	NONE = "None",
}

export enum SettingPropEnum {
	FAIL_SAFE_MESSAGE = "fail_safe_message",
	CLOCK_TYPE = "clock_type",
}

export enum ClockType {
	STATIC = "static",
	RUNNING = "running",
}
