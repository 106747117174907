import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {UserStatus} from "data/enums";

export interface IHeaderController extends ViewController {
	get isAuthorized(): boolean;

	get logoLink(): string;

	get hasAccess(): boolean;
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get hasAccess(): boolean {
		if (!this._userStore.user) {
			return false;
		}
		return this._userStore.user?.status === UserStatus.Approved;
	}

	get logoLink(): string {
		if (!this.isAuthorized) {
			return "/login";
		}
		return "/";
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
