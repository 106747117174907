import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ISquad, ITournament} from "data/types/entities";

interface IParams {
	squadId: number;
	tournamentId: number;
}

export interface IFixtureInfoSquadController extends ViewController<IParams> {
	get squadName(): string;

	get squadScore(): number;

	get squadColor(): string;

	updateParams: (tournamentId: number, squadId: number) => void;

	isUsedTimeout: (timeoutNumber: number) => boolean;
}

@injectable()
export class FixtureInfoSquadController implements IFixtureInfoSquadController {
	@observable private _squadId: Empty<number>;
	@observable private _tournamentId: Empty<number>;

	constructor(
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get squadName(): string {
		return this.squad?.shortName || "-";
	}

	get squadScore(): number {
		if (!this.tournament) {
			return 0;
		}
		const {id, score} = this.tournament.homeSquad;
		const awayScore = this.tournament.awaySquad.score;

		const isHome = id === this._squadId;
		return (isHome ? score : awayScore) || 0;
	}

	get squadColor() {
		return this.squad?.color || "#000";
	}

	protected get squad(): Empty<ISquad> {
		return this._squadsStore.getSquadById(this._squadId);
	}

	protected get tournament(): Empty<ITournament> {
		return this._roundsStore.getTournamentById(this._tournamentId);
	}

	updateParams = (tournamentId: number, squadId: number) => {
		this._squadId = squadId;
		this._tournamentId = tournamentId;
	};

	isUsedTimeout = (timeoutNumber: number) => {
		if (this.tournament?.timeouts) {
			const {homeSquad, timeouts} = this.tournament;
			const isHome = homeSquad.id === this._squadId;
			const timeout = isHome ? timeouts.Home || 0 : timeouts.Away || 0;

			return timeoutNumber <= timeout;
		}

		return false;
	};
	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._squadId = param.squadId;
		this._tournamentId = param.tournamentId;
	}
}
