import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {noop} from "lodash";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import type {IClockStore} from "data/stores/clock/clock.store";

export interface IHOCMainController extends ViewController {
	get isLoading(): boolean;
}

@injectable()
export class Hoc_mainController implements IHOCMainController {
	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore,
		@inject(Bindings.ClockStore) private _clocksStore: IClockStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this._isLoading = true;

		void Promise.all([this.fetchUser(), this.fetchJsons()])
			.catch(noop)
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	}

	@action
	private async fetchUser() {
		await this._userStore.fetchUser();
	}

	private async fetchJsons() {
		await Promise.all([
			this._roundsStore.fetchRounds(),
			this._squadsStore.fetchSquads(),
			this._settingsStore.fetch(),
			this._clocksStore.fetch(),
		]);
	}
}
