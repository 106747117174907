import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {ITournament} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {IPlay} from "data/types/matchStats";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import {last} from "lodash";
import type {ISquadsStore} from "data/stores/squads/squads.store";

export interface IRecentPlayController extends ViewController {
	get recentPlay(): Empty<IPlay>;
	get recentPlayColor(): string;
}

@injectable()
export class RecentPlayController implements IRecentPlayController {
	@observable protected _tournamentId: Empty<number>;

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	get recentPlay() {
		return last(this._matchStatsStore.plays);
	}

	get recentPlayColor() {
		if (this.recentPlay?.teamInPossession) {
			const squad = this._squadsStore.getSquadById(this.recentPlay?.teamInPossession);

			if (squad?.color) {
				return squad?.color;
			}
		}
		return "#222";
	}

	protected get tournament(): Empty<ITournament> {
		return this._roundsStore.selectedTournament;
	}
}
