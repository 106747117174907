import TableRow from "@mui/material/TableRow";
import * as React from "react";
import {Collapse} from "@mui/material";
import {useViewController} from "data/services/locator";
import {ITableCollapseRowController} from "views/components/table/table_collapse_row/table_collapse_row.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import TableCell from "@mui/material/TableCell";

export enum TableRowCollapseType {
	PLAYLIST,
}

interface IProps {
	CollapseComponent: React.ReactNode;
	collapseColSpan: number;
	highlightClass?: string;
	children: React.ReactElement;
	type: TableRowCollapseType;
}

export const TableRowCollapse: React.FC<IProps> = observer(
	({children, collapseColSpan, CollapseComponent, highlightClass = "", type}) => {
		const {isOpen, toggleVisibility} = useViewController<ITableCollapseRowController>(
			Bindings.TableCollapseRowController,
			{
				type,
			}
		);

		return (
			<React.Fragment>
				<TableRow
					className={highlightClass || "collapse"}
					onClick={toggleVisibility}
					sx={{cursor: "pointer", padding: 0}}>
					{children}
				</TableRow>
				<TableRow className="collapse">
					<TableCell sx={{padding: 0}} colSpan={collapseColSpan}>
						<Collapse in={isOpen} timeout="auto" unmountOnExit>
							{CollapseComponent}
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		);
	}
);
