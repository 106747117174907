import {AbstractValidator} from "data/utils/validators/abstract.validator";
import {THTMLFormElements} from "data/types/validators";

export class PasswordValidator extends AbstractValidator {
	getErrorMessage(): string {
		return "Password should contain at least 12 characters";
	}

	validate(formControl: THTMLFormElements): boolean {
		return formControl.value.length >= 12;
	}
}
