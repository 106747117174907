import {action, makeAutoObservable, observable} from "mobx";
import {INavigateParams, ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ValidationScheme} from "data/types/validators";
import {PasswordValidator} from "data/utils/validators/PasswordValidator";
import {Bindings} from "data/constants/bindings";
import type {IFormValidator} from "data/utils/validators/FormValidator";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {Empty} from "data/types/generics";
import React from "react";
import {IResetPasswordPayload} from "data/types/entities";
import {ISuccessModalPayload} from "data/types/modal";
import {ModalType} from "data/enums";
import {AxiosError} from "axios";
import {IAxiosError} from "data/types/api";
import {NavigateFunction} from "react-router-dom";

interface IForm extends HTMLFormElement {
	code: HTMLInputElement;
	// email: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IModalResetPasswordController extends ViewController<INavigateParams> {
	closeModal: () => void;

	togglePasswordVisibility: () => void;

	toggleConfirmPasswordVisibility: () => void;

	onFormSubmit: (event: React.SyntheticEvent<IForm>) => void;

	onFormChange: (event: React.ChangeEvent<IForm>) => void;

	get isLoading(): boolean;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;

	get formErrors(): Record<string, Empty<string>>;

	get error(): Empty<string>;
}

@injectable()
export class ModalResetPasswordController implements IModalResetPasswordController {
	@observable protected _navigate: Empty<NavigateFunction>;
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
	};

	constructor(
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
		this._formValidator.enterScheme(this._validationScheme);
	}

	@observable private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	get formErrors(): Record<string, Empty<string>> {
		return this._formValidator.formErrors;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	dispose(): void {
		return;
	}

	init(param: INavigateParams): void {
		this._navigate = param.navigate;
	}

	@action
	public onFormChange = (event: React.ChangeEvent<IForm>) => {
		const name = event.target.name;
		if (!name) {
			return;
		}
		this._formValidator.clearError(name);
		this._error = undefined;
	};

	public onFormSubmit = (event: React.SyntheticEvent<IForm>) => {
		event.preventDefault();
		this.clearErrors();

		const {code, password, confirmPassword} = event.currentTarget;

		const isValid = this._formValidator.validate(event.currentTarget);
		if (!isValid) {
			return;
		}

		if (password.value !== confirmPassword.value) {
			this._formValidator.setError("confirmPassword", "true");
			return;
		}

		const payload: IResetPasswordPayload = {
			token: code.value,
			password: password.value,
		};

		this._isLoading = true;

		this._userStore
			.resetPasswordRequest(payload)
			.then(this.openSuccessModal.bind(this))
			.catch(this.onError.bind(this))
			.finally(this.onFinally.bind(this));
	};

	public closeModal = () => {
		this._modalsStore.hideModal();
		this._navigate?.("/login");
	};

	@action
	public togglePasswordVisibility = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};
	@action
	public toggleConfirmPasswordVisibility = () => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};

	protected clearErrors() {
		this._formValidator.clearErrors();
		this._error = undefined;
	}

	@action
	private onError = (error: AxiosError<IAxiosError>) => {
		this._error = error.response?.data?.errors[0]?.message || error.message;
	};

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	protected openSuccessModal() {
		this._modalsStore.showModal<ISuccessModalPayload>(ModalType.SUCCESS, {
			title: "Password Updated!",
			message: "You have successfully updated your password.",
			buttonText: "Back to login",
			closeFunction: () => this._navigate?.("/login"),
		});
	}
}
