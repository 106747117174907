import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {ISquad} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {getImageFromBackend} from "data/utils/helpers";

interface IParams {
	squadId: number;
}

export interface ISquadImageController extends ViewController<IParams> {
	get squad(): Empty<ISquad>;
	get squadImagePath(): string;

	setSquadId: (squadId: number) => void;
}

@injectable()
export class SquadImageController implements ISquadImageController {
	@observable protected _squadId: Empty<number>;
	constructor(@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._squadId = param.squadId;
	}

	get squad(): Empty<ISquad> {
		return this._squadsStore.getSquadById(this._squadId);
	}

	get squadImagePath(): string {
		return getImageFromBackend(this.squad?.shortName);
	}

	setSquadId = (squadId: number) => {
		this._squadId = squadId;
	};
}
