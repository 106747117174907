import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {PlayerUtils} from "data/utils/player";
import type {
	IMatchStats,
	ISquadMatchStats,
	IPlay,
	IScoringPlay,
	IDrive,
	IStatPlayer,
	IMatchStatsPlayerSquads,
	IMatchStatPlays,
} from "data/types/matchStats";
import {WithRowIndex} from "data/types/generics";

export interface IMatchStatsStore {
	isLive: boolean;

	get isLoading(): boolean;

	get allStats(): IMatchStats | null;

	get plays(): IPlay[];

	get drives(): WithRowIndex<IDrive>[];

	get scoringPlays(): IScoringPlay[];

	get squads(): ISquadMatchStats[];

	get players(): IStatPlayer[];

	get passingPlayers(): IStatPlayer[];

	get receivingPlayers(): IStatPlayer[];

	get rushingPlayers(): IStatPlayer[];

	get defensivePlayers(): IStatPlayer[];

	get fieldGoalsPlayers(): IStatPlayer[];

	get puntsPlayers(): IStatPlayer[];

	get puntsReturnsPlayers(): IStatPlayer[];

	get kickOffPlayers(): IStatPlayer[];

	get kickOffReturnsPlayers(): IStatPlayer[];

	get fieldGoalsMissedReturnsPlayers(): IStatPlayer[];

	get teamLossesPlayers(): IStatPlayer[];

	fetchStats: (matchId: number, season: number) => Promise<void>;
	fetchPlays: (matchId: number, season: number) => Promise<void>;
	fetchStatsLive: (matchId: number, season: number) => Promise<void>;
	fetchPlaysLive: (matchId: number, season: number) => Promise<void>;

	clear(): void;
}

@injectable()
export class MatchStatsStore implements IMatchStatsStore {
	@observable public isLive = false;

	@observable private _matchStats: IMatchStatsPlayerSquads = {
		squads: [],
		players: [],
	};
	@observable private _matchPlays: IMatchStatPlays = {
		drives: [],
		plays: [],
		scoringPlays: [],
	};

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	private set isLoading(value: boolean) {
		this._isLoading = value;
	}

	private set matchStats(matchStats: IMatchStatsPlayerSquads | null) {
		this._matchStats = matchStats || {
			squads: [],
			players: [],
		};
	}

	get matchStats(): IMatchStatsPlayerSquads {
		return this._matchStats;
	}

	private set matchPlays(matchPlays: IMatchStatPlays | null) {
		this._matchPlays = matchPlays || {
			drives: [],
			plays: [],
			scoringPlays: [],
		};
	}

	get allStats(): IMatchStats {
		return {
			...this._matchStats,
			...this._matchPlays,
		};
	}

	get players(): IStatPlayer[] {
		return this.allStats?.players || [];
	}

	get plays() {
		return this.allStats?.plays || [];
	}

	get drives() {
		return (this.allStats?.drives || []).map((it, rowIndex) => ({
			...it,
			rowIndex,
		}));
	}

	get scoringPlays() {
		return this.allStats?.scoringPlays || [];
	}

	get squads() {
		return this.allStats?.squads || [];
	}

	get passingPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isPassing);
	}

	get receivingPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isReceiving);
	}

	get rushingPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isRushing);
	}

	get defensivePlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isDefensive);
	}

	get fieldGoalsPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isFieldGoals);
	}

	get puntsPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isPunts);
	}

	get puntsReturnsPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isPuntsReturns);
	}

	get kickOffPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isKickOff);
	}

	get kickOffReturnsPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isKickOffReturns);
	}

	get fieldGoalsMissedReturnsPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isFieldGoalsMissedReturns);
	}

	get teamLossesPlayers(): IStatPlayer[] {
		return this.players.filter(PlayerUtils.isTeamLosses);
	}

	clear() {
		this.matchPlays = null;
		this.matchStats = null;
	}

	@action
	public async fetchStats(matchId: number, season: number) {
		try {
			this.isLoading = true;

			const response = await this._jsonProvider.matchStats(matchId, season);

			if (!Array.isArray(response.data)) {
				throw new Error("Invalid match stats");
			}

			const matchStats = response.data[0];

			runInAction(() => {
				this.matchStats = matchStats;
				this.isLoading = false;
			});
		} catch (e: unknown) {
			throw new Error((e as Error).message);
		} finally {
			runInAction(() => {
				this.isLoading = false;
			});
		}
	}

	@action
	public async fetchPlays(matchId: number, season: number) {
		try {
			this.isLoading = true;

			const response = await this._jsonProvider.matchStatsPlays(matchId, season);

			if (!Array.isArray(response.data)) {
				throw new Error("Invalid plays stats");
			}

			const matchPlays = response.data[0];

			runInAction(() => {
				this.matchPlays = matchPlays;
				this.isLoading = false;
			});
		} catch (e: unknown) {
			throw new Error((e as Error).message);
		} finally {
			runInAction(() => {
				this.isLoading = false;
			});
		}
	}

	/**
	 * Fetches stats without loading indications
	 * @param matchId
	 */
	@action
	public async fetchStatsLive(matchId: number, season: number) {
		this.isLive = true;
		const response = await this._jsonProvider.matchStats(matchId, season);

		if (!Array.isArray(response.data)) {
			throw new Error("Invalid match stats live");
		}

		const matchStats = response.data[0];

		runInAction(() => {
			this.matchStats = matchStats;
		});
	}

	@action
	public async fetchPlaysLive(matchId: number, season: number) {
		this.isLive = true;
		const response = await this._jsonProvider.matchStatsPlays(matchId, season);

		if (!Array.isArray(response.data)) {
			throw new Error("Invalid play stats live");
		}

		const matchPlays = response.data[0];

		runInAction(() => {
			this.matchPlays = matchPlays;
		});
	}
}
