import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import {IPlay, IStatPlayerWithPlayStats} from "data/types/matchStats";
import {compact, orderBy} from "lodash";
import {PlayerUtils} from "data/utils/player";

export interface IPlayTableChildController extends ViewController {
	set play(play: IPlay);
	get homePlayers(): IStatPlayerWithPlayStats[];
	get awayPlayers(): IStatPlayerWithPlayStats[];
}

@injectable()
export class PlayTableChildController implements IPlayTableChildController {
	private _play: IPlay | null = null;

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.MatchStatsStore) private _playersStore: IMatchStatsStore
	) {
		makeAutoObservable(this);
	}

	get homePlayers() {
		const tournament = this._roundsStore.selectedTournament;
		return this.playersByPlay.filter((player) => PlayerUtils.isFirstSquad(player, tournament));
	}

	get awayPlayers() {
		const tournament = this._roundsStore.selectedTournament;
		return this.playersByPlay.filter((player) => PlayerUtils.isSecondSquad(player, tournament));
	}

	private get playersByPlay(): IStatPlayerWithPlayStats[] {
		return orderBy(
			compact(
				this.playerListByPlay.map((actionPlayer) => {
					const player = this._playersStore.players.find(
						(player) => player.feedId === actionPlayer.id
					);

					if (!player) {
						return null;
					}

					const stats: IStatPlayerWithPlayStats = {
						...player,
						play: actionPlayer.playStats,
					};

					return stats;
				})
			),
			(player) => player.play.sequence || 0,
			"asc"
		);
	}

	private get playerListByPlay() {
		return (
			this._play?.actions.flatMap((action) =>
				action.players.map((player) => ({
					id: player.id,
					playStats: action,
				}))
			) || []
		);
	}

	init(param: void) {
		return;
	}

	dispose() {
		return;
	}

	set play(play: IPlay) {
		this._play = play;
	}
}
