import {ThemeOptions} from "@mui/material/styles/createTheme";
import {colorsTheme} from "assets/theme/colors.theme";
import {secondaryFont} from "assets/theme/typography.theme";
import {IThemeButton} from "data/types/theme";
import {CSSObject} from "@mui/styled-engine";

declare module "@mui/material/Button" {
	// allows to rewrite theme options

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonPropsColorOverrides {
		adornment: true;
		link: true;
		pure: true;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonClasses {
		textAdornment: string;
		textLink: string;
		textPure: string;
	}
}

function generateButtonStyle(styles: IThemeButton): CSSObject {
	const hoverState = {
		background: styles.hoverBackground,
		color: styles.hoverColor,
		borderColor: styles.hoverBorderColor,
		transition: ".25s",

		path: {
			fill: styles.hoverColor,
		},
	};

	return {
		background: styles.background,
		color: styles.color,
		borderColor: styles.borderColor,
		transition: ".5s",
		display: "flex",
		justifyContent: "center",
		gap: "8px",

		":hover": hoverState,
		":active": hoverState,
		":focus": hoverState,
		":disabled": {
			background: styles.disabledBackground,
			color: styles.disabledColor,
			borderColor: styles.disabledBorderColor,
		},

		"@media (max-width: 640px)": {
			fontSize: "16px",
			padding: "6px 12px",
		},
	};
}

export const buttonTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					padding: "8px",
				},
				textPrimary: () => generateButtonStyle(colorsTheme.color.components.button.primary),
				outlinedPrimary: () =>
					generateButtonStyle(colorsTheme.color.components.button.secondary),
				textAdornment: () => ({
					...generateButtonStyle(colorsTheme.color.components.button.adornment),
					width: "24px",
					height: "24px",
					padding: 0,
					minWidth: "unset",
				}),
				textLink: () => ({
					...generateButtonStyle(colorsTheme.color.components.button.link),
					padding: 0,
					minWidth: "unset",
				}),
				textPure: () => ({
					...generateButtonStyle(colorsTheme.color.components.button.link),
					color: "#000000",
					textTransform: "capitalize",
					padding: 0,
					minWidth: "unset",
					minHeight: "40px",
				}),
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					...generateButtonStyle(colorsTheme.color.components.button.toggle),
					padding: "1px 9px",
					fontWeight: 400,
					fontFamily: secondaryFont,
					border: "none",

					"&.Mui-selected": {
						background: colorsTheme.color.brand.primary,
						color: colorsTheme.color.text.white,
					},
					"&.Mui-selected:hover": {
						background: colorsTheme.color.brand.primary,
						color: colorsTheme.color.text.white,
					},

					"@media (min-width: 880px)": {
						fontSize: "16px",
					},
				},
			},
		},
	},
};
