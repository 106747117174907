import React from "react";
import {ReactComponent as GeniusIcon} from "assets/img/icons/geniusIcon.svg";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IAuthController} from "views/controllers/auth/auth.controller";
import {observer} from "mobx-react";

const Wrapper = styled.a`
	display: flex;
	flex-flow: row;
	justify-content: flex-end;
	padding: 20px 40px 20px;
	align-items: center;
	font-family: "Red Hat Display", sans-serif;
	margin-top: auto;

	svg {
		color: #2532e8;
	}

	@media (max-width: 880px) {
		flex-flow: row;
		justify-content: center;
		align-items: center;
		gap: 10px;
		text-align: center;
		flex-wrap: wrap;
		svg {
			flex-shrink: 0;
		}
	}

	span {
		background-image: linear-gradient(#2532e8, #2532e8);
		background-size: 0 2px;
		background-position: bottom left;
		background-repeat: no-repeat;
		transition: all 0.5s ease;
		padding: 4px 0;
	}
	&:hover {
		span {
			transition: all 0.5s ease;
			background-size: 100% 2px;
		}
	}
`;

export const Footer: React.FC = observer(() => {
	const controller = useViewController<IAuthController>(Bindings.AuthController);

	if (!controller.isAuthorized) {
		return null;
	}

	return (
		<Wrapper
			href={"https://geniussports.com/genius-marketing-suite/"}
			target={"_blank"}
			rel={"noreferrer, noopener"}>
			<GeniusIcon />
			<span>Powered by Genius Sports</span>
		</Wrapper>
	);
});
