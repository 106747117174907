import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {TableRowCollapseType} from "views/components/table/table_collapse_row/table_collapse_row.component";
import {createConnextraScriptTag, ConnextraType} from "data/utils/connextra";

interface IParams {
	type: TableRowCollapseType;
}

export interface ITableCollapseRowController extends ViewController<IParams> {
	toggleVisibility: () => void;

	get isOpen(): boolean;
}

@injectable()
export class TableCollapseRowController implements ITableCollapseRowController {
	@observable _type: TableRowCollapseType | null = null;
	constructor() {
		makeAutoObservable(this);
	}

	@observable protected _isOpen: boolean = false;

	get isOpen(): boolean {
		return this._isOpen;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._type = param.type;
	}

	@action
	public toggleVisibility = () => {
		this._isOpen = !this._isOpen;
		if (this._isOpen) {
			createConnextraScriptTag(ConnextraType.PLAYLIST_EXPANSION);
		}
	};
}
