import {Box, Snackbar, Alert} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IGameMessageController} from "views/components/modals/modal_game_message/modal_game_message.controller";
import {Bindings} from "data/constants/bindings";
import React from "react";
import {observer} from "mobx-react";

export const ModalGameMessageComponent: React.FC = observer(() => {
	const controller = useViewController<IGameMessageController>(Bindings.GameMessageController);

	return (
		<Box>
			<Snackbar
				anchorOrigin={{vertical: "top", horizontal: "center"}}
				open={controller.isOpen}
				onClose={() => controller.handleClose()}
				message={controller.message}
				key={controller.message}
				autoHideDuration={5000}>
				<Alert severity={controller.color}>{controller.message}</Alert>
			</Snackbar>
		</Box>
	);
});
