import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL, PUB_NUB_ENV} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {type IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {type IMatchStatsStore, MatchStatsStore} from "data/stores/match_stats/match_stats.store";
import {type ISquadsStore, SquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {HeaderController, IHeaderController} from "views/components/header/header.controller";
import {
	HeaderAccountController,
	IHeaderAccountController,
} from "views/components/header_account/header_account.controller";
import {
	ISubHeaderController,
	SubHeaderController,
} from "views/components/sub_header/subheader.controller";
import {
	IRegistrationController,
	RegistrationController,
} from "views/pages/registration/registration.controller";
import {FormValidator, IFormValidator} from "data/utils/validators/FormValidator";
import {
	Hoc_mainController,
	IHOCMainController,
} from "views/components/hoc/hoc_main/hoc_main.controller";
import {
	HOCUserManagementController,
	IHOCUserManagementController,
} from "views/components/hoc/hoc_user_management/hoc_user_management.controller";
import {
	IModalUserManagementController,
	ModalUserManagementController,
} from "views/components/modals/modal_user_management/modal_user_management.controller";
import {IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	IModalForgotPasswordController,
	ModalForgotPasswordController,
} from "views/components/modals/modal_forgot_password/modal_forgot_password.controller";
import {
	IModalSuccessController,
	ModalSuccessController,
} from "views/components/modals/modal_success/modal_success.controller";
import {
	IModalResetPasswordController,
	ModalResetPasswordController,
} from "views/components/modals/modal_reset_password/modal_reset_password.controller";
import {
	ISubHeaderFiltersController,
	SubHeaderFiltersController,
} from "views/components/sub_header_filters/sub_header_filters.controller";
import {
	IMatchStatusController,
	MatchStatusController,
} from "views/components/match_status/match_status.controller";
import {
	GameMessageController,
	IGameMessageController,
} from "views/components/modals/modal_game_message/modal_game_message.controller";
import {GameMessageStore, IGameMessageStore} from "data/stores/game_message/game_message.store";
import {
	FixturePageController,
	IFixturePageController,
} from "views/pages/fixture/fixture_page.controller";
import {
	FixtureHubController,
	IFixtureHubController,
} from "views/pages/fixture_hub/fixture_hub.controller";
import {
	FixtureHubTournamentController,
	IFixtureHubTournamentController,
} from "views/components/fixture_hub_tournament/fixture_hub_tournament.controller";
import {
	FixtureInfoController,
	IFixtureInfoController,
} from "views/components/fixtureInfo/fixture_info.controller";
import {
	FixtureInfoSquadController,
	IFixtureInfoSquadController,
} from "views/components/fixture_info_squad/fixture_info_squad.controller";

import {
	FixtureInfoTableController,
	IFixtureInfoTableController,
} from "views/components/fixture_info_table/fixture_info_table.controller";
import {
	ISquadImageController,
	SquadImageController,
} from "views/components/squad_image/squad_image.controller";
import {
	ITableCollapseRowController,
	TableCollapseRowController,
} from "views/components/table/table_collapse_row/table_collapse_row.controller";

import {PlayersController, IPlayersController} from "views/controllers/players/players.controller";
import {
	ITeamStatsController,
	TeamStatsController,
} from "views/components/tables/game_cast_tables/team_stats/team_stats.controller";
import {ITeamsController, TeamsController} from "views/controllers/teams/teams.controller";
import {
	IPlayListController,
	PlayListController,
} from "views/controllers/play_list/play_list.controller";
import {
	IDriveChartTableController,
	DriveChartTableController,
} from "views/components/tables/drive_chart_tables/drive_chart_table.controller";
import {IChecksumsStore, ChecksumsStore} from "data/stores/checksums/checksums.store";
import {
	IHOCLiveScoreController,
	HOCLiveScoreController,
} from "views/components/hoc/hoc_live_score/hoc_live_score.controller";
import {
	IPlayTableChildController,
	PlayTableChildController,
} from "views/components/tables/play_list_tables/play_table_child/play_table_child.controller";
import {HighlightStore, IHighlightStore} from "data/stores/highlight/highlight.store";
import {
	IFixtureTabsController,
	FixtureTabsController,
} from "views/components/fixture_tabs/fixture_tabs.controller";
import {
	TableCellHighlightController,
	ITableCellHighlightController,
} from "views/components/table/table_cell_highlight.controller";
import {
	IRecentPlayController,
	RecentPlayController,
} from "views/components/recent_play/recent_play.controller";
import {
	IPubNubClientService,
	PubNubClientService,
} from "data/services/pub_nub/pub_nub_client.servise";
import {GameBookApiProvider, IGameBookApiProvider} from "data/providers/api/game_book.api.provider";
import {
	GameBookButtonController,
	IGameBookButtonController,
} from "views/components/game_book_button/game_book_button.controller";
import {type ISettingsStore, SettingsStore} from "data/stores/settings/settings.store";
import {
	ISettingsController,
	SettingsController,
} from "views/controllers/settings/settings.controller";
import {type IClockStore, ClockStore} from "data/stores/clock/clock.store";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL + "en/",
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);

	bind<IPubNubClientService>(Bindings.PubNubClientService).toConstantValue(
		new PubNubClientService(
			{
				secretKey: "sec-c-ZjRmZjdlM2EtZTFhZi00Mzg4LTk1YjItZDgwNTU4NmRhNjYz",
				subscribeKey: "sub-c-80e440da-3435-4245-a6ff-6a14d1a0970d",
			},
			PUB_NUB_ENV
		)
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IGameBookApiProvider>(Bindings.GameBookApiProvider).to(GameBookApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IMatchStatsStore>(Bindings.MatchStatsStore).to(MatchStatsStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);
	bind<IGameMessageStore>(Bindings.GameMessageStore).to(GameMessageStore).inSingletonScope();
	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore).inSingletonScope();
	bind<IChecksumsStore>(Bindings.ChecksumsStore).to(ChecksumsStore).inSingletonScope();
	bind<IHighlightStore>(Bindings.HighlightStore).to(HighlightStore).inSingletonScope();
	bind<ISettingsStore>(Bindings.SettingsStore).to(SettingsStore).inSingletonScope();
	bind<IClockStore>(Bindings.ClockStore).to(ClockStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IHeaderAccountController>(Bindings.HeaderAccountController).to(HeaderAccountController);
	bind<ISubHeaderController>(Bindings.SubHeaderController).to(SubHeaderController);
	bind<IRegistrationController>(Bindings.RegistrationController).to(RegistrationController);
	bind<IHOCMainController>(Bindings.HOCMainController).to(Hoc_mainController);
	bind<IHOCUserManagementController>(Bindings.HOCUserManagementController).to(
		HOCUserManagementController
	);
	bind<IModalUserManagementController>(Bindings.ModalUserManagementController).to(
		ModalUserManagementController
	);
	bind<IModalForgotPasswordController>(Bindings.ModalForgotPasswordController).to(
		ModalForgotPasswordController
	);
	bind<IModalSuccessController>(Bindings.ModalSuccessController).to(ModalSuccessController);
	bind<IModalResetPasswordController>(Bindings.ModalResetPasswordController).to(
		ModalResetPasswordController
	);
	bind<ISubHeaderFiltersController>(Bindings.SubHeaderFiltersController).to(
		SubHeaderFiltersController
	);
	bind<IMatchStatusController>(Bindings.MatchStatusController).to(MatchStatusController);
	bind<IRecentPlayController>(Bindings.RecentPlayController).to(RecentPlayController);
	bind<IFixturePageController>(Bindings.FixturePageController).to(FixturePageController);
	bind<IFixtureHubController>(Bindings.FixtureHubController).to(FixtureHubController);
	bind<IFixtureHubTournamentController>(Bindings.FixtureHubTournamentController).to(
		FixtureHubTournamentController
	);
	bind<IFixtureInfoController>(Bindings.FixtureInfoController).to(FixtureInfoController);
	bind<IFixtureInfoSquadController>(Bindings.FixtureInfoSquadController).to(
		FixtureInfoSquadController
	);
	bind<IFixtureTabsController>(Bindings.FixtureTabsController).to(FixtureTabsController);
	bind<ITableCellHighlightController>(Bindings.TableCellHighlightController).to(
		TableCellHighlightController
	);
	bind<IFixtureInfoTableController>(Bindings.FixtureInfoTableController).to(
		FixtureInfoTableController
	);
	bind<ISquadImageController>(Bindings.SquadImageController).to(SquadImageController);
	bind<ITableCollapseRowController>(Bindings.TableCollapseRowController).to(
		TableCollapseRowController
	);
	bind<IPlayTableChildController>(Bindings.PlayTableChildController).to(PlayTableChildController);

	bind<IHOCLiveScoreController>(Bindings.HOCLiveScoreController).to(HOCLiveScoreController);
	bind<IFormValidator>(Bindings.FormValidator).to(FormValidator);
	bind<IGameMessageController>(Bindings.GameMessageController).to(GameMessageController);

	bind<IPlayersController>(Bindings.PlayersController).to(PlayersController);
	bind<ITeamStatsController>(Bindings.TeamStatsController).to(TeamStatsController);
	bind<ITeamsController>(Bindings.TeamsController).to(TeamsController);
	bind<IPlayListController>(Bindings.PlayListController).to(PlayListController);
	bind<IDriveChartTableController>(Bindings.DriveChartTableController).to(
		DriveChartTableController
	);
	bind<IGameBookButtonController>(Bindings.GameBookButtonController).to(GameBookButtonController);
	bind<ISettingsController>(Bindings.SettingsController).to(SettingsController);
});
