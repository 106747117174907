import {makeAutoObservable, observable} from "mobx";
import {INavigateParams, ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import React from "react";
import {SeasonFilter} from "data/enums";
import {ITextFieldSelectChange} from "data/types/entities";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {Empty} from "data/types/generics";
import {NavigateFunction} from "react-router-dom";
import {first, orderBy, identity} from "lodash";

export interface ISubHeaderFiltersController extends ViewController<INavigateParams> {
	changeSeason: (event: React.MouseEvent<HTMLElement>, value: SeasonFilter) => void;
	onYearChange: (event: ITextFieldSelectChange) => void;
	onWeekChange: (event: ITextFieldSelectChange) => void;

	get years(): IRoundsStore["years"];

	get weeks(): IRoundsStore["weeks"];

	get list(): IRoundsStore["list"];

	get selectedYear(): IRoundsStore["selectedYear"];

	get selectedWeek(): IRoundsStore["selectedWeek"];

	get season(): IRoundsStore["selectedSeasonState"];
}

@injectable()
export class SubHeaderFiltersController implements ISubHeaderFiltersController {
	@observable private _navigate: Empty<NavigateFunction>;

	constructor(@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore) {
		makeAutoObservable(this);
	}

	get years() {
		return orderBy(this._roundsStore.years, identity, ["desc"]);
	}

	get weeks() {
		return this._roundsStore.weeks;
	}

	get list() {
		return this._roundsStore.yearRounds;
	}

	get selectedYear() {
		return this._roundsStore.selectedYear;
	}

	get selectedWeek() {
		return this._roundsStore.selectedWeek;
	}

	get season() {
		return this._roundsStore.selectedSeasonState;
	}

	dispose(): void {
		return;
	}

	init(param: INavigateParams): void {
		this._navigate = param.navigate;

		this.setInitialFilters();
	}

	setInitialFilters() {
		const filters = this.initialFilters;

		this._roundsStore.selectedYear = filters.selectedYear;
		this._roundsStore.selectedWeek = filters.selectedWeek;
		this._roundsStore.selectedSeasonState = filters.selectedSeasonState;
	}

	private get initialFilters() {
		const activeRound =
			this._roundsStore.activeRound || first(this._roundsStore.scheduleRounds);
		const roundType = activeRound?.tournaments?.at(0)?.type;
		if (activeRound) {
			return {
				selectedYear: activeRound.season,
				selectedSeasonState: roundType ? roundType : SeasonFilter.RegularSeason,
				selectedWeek: activeRound.id,
			};
		}

		return {
			selectedYear: new Date().getFullYear(),
			selectedSeasonState: SeasonFilter.RegularSeason,
			selectedWeek: 0,
		};
	}
	public changeSeason = (event: React.MouseEvent<HTMLElement>, value: SeasonFilter): void => {
		if (!value) {
			return;
		}
		this._roundsStore.selectedSeasonState = value;
		this._roundsStore.selectedWeek = 0;
		this.navigateToHub();
	};

	public onWeekChange = (event: ITextFieldSelectChange): void => {
		const week = Number(event.target.value);
		if (!week) {
			return;
		}
		this._roundsStore.selectedWeek = week;
		this.navigateToHub();
	};

	public onYearChange = (event: ITextFieldSelectChange): void => {
		const year = Number(event.target.value);
		if (!year) {
			return;
		}
		this._roundsStore.selectedYear = year;
		this._roundsStore.selectedWeek = 0;
		void this._roundsStore.fetchRounds();
		this.navigateToHub();
	};

	private navigateToHub() {
		this._roundsStore.clearSelectedTournament();
		this._navigate?.("/fixture");
	}
}
