import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {AlertColor} from "@mui/material/Alert/Alert";

export interface IGameMessage {
	message: string;
	color: AlertColor;
}

export interface IGameMessageStore {
	get getState(): IGameMessage | null;
	showMessage(payload: IGameMessage): void;
	close(): void;
}

@injectable()
export class GameMessageStore implements IGameMessageStore {
	@observable private _state: IGameMessage | null = null;

	get getState() {
		return this._state;
	}

	showMessage(payload: IGameMessage) {
		this._state = payload;
	}

	close() {
		this._state = null;
	}

	constructor() {
		makeAutoObservable(this);
	}
}
