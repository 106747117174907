export const Bindings = {
	// Providers
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	GameBookApiProvider: Symbol("GameBookApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	PubNubClientService: Symbol("PubNubClientService"),

	// Stores
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	MatchStatsStore: Symbol("MatchStatsStore"),
	SquadsStore: Symbol("SquadsStore"),
	SquadsStatsStore: Symbol("SquadsStatsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	ModalsStore: Symbol("ModalsStore"),
	ChecksumsStore: Symbol("ChecksumsStore"),
	HighlightStore: Symbol("HighlightStore"),
	GameMessageStore: Symbol("GameMessageStore"),

	HOCLiveScoreController: Symbol("HOCLiveScoreController"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	HeaderController: Symbol("HeaderController"),
	HeaderAccountController: Symbol("HeaderAccountController"),
	SubHeaderController: Symbol("SubHeaderController"),
	RegistrationController: Symbol("RegistrationController"),
	SubHeaderFiltersController: Symbol("SubHeaderFiltersController"),
	MatchStatusController: Symbol("MatchStatusController"),
	RecentPlayController: Symbol("RecentPlayController"),
	FixturePageController: Symbol("FixturePageController"),
	FixtureHubController: Symbol("FixtureHubController"),
	FixtureHubTournamentController: Symbol("FixtureHubTournamentController"),
	FixtureInfoController: Symbol("FixtureInfoController"),
	FixtureInfoSquadController: Symbol("FixtureInfoSquadController"),
	FixtureInfoTableController: Symbol("FixtureInfoTableController"),
	FixtureTabsController: Symbol("FixtureTabsController"),
	SquadImageController: Symbol("SquadImageController"),
	TableCollapseRowController: Symbol("TableCollapseRowController"),
	PlayTableChildController: Symbol("PlayTableChildController"),
	TableCellHighlightController: Symbol("TableCellHighlightController"),
	GameBookButtonController: Symbol("GameBookButtonController"),
	// HOCs
	HOCMainController: Symbol("HOCMainController"),
	HOCUserManagementController: Symbol("HOCUserManagementController"),

	// Modals
	ModalForgotPasswordController: Symbol("ModalForgotPasswordController"),
	ModalSuccessController: Symbol("ModalSuccessController"),
	ModalResetPasswordController: Symbol("ModalResetPasswordController"),
	ModalUserManagementController: Symbol("ModalUserManagementController"),

	// Helpers
	FormValidator: Symbol("General validator for forms"),
	GameMessageController: Symbol("GameMessageController"),

	//Tabs
	TeamsController: Symbol("TeamsController"),
	PlayersController: Symbol("PlayersController"),
	TeamStatsController: Symbol("TeamStatsController"),
	PlayListController: Symbol("PlayListController"),

	DriveChartTableController: Symbol("DriveChartTableController"),
	SettingsStore: Symbol("SettingsStore"),
	SettingsController: Symbol("SettingsController"),
	ClockStore: Symbol("ClockStore"),
};
