import {ThemeOptions} from "@mui/material/styles/createTheme";
import {colorsTheme} from "assets/theme/colors.theme";
import {MUI_FOCUS_CLASS} from "data/constants";

export const inputsTheme: ThemeOptions = {
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					[`${MUI_FOCUS_CLASS} fieldset.MuiOutlinedInput-notchedOutline`]: {
						borderColor: colorsTheme.color.brand.neutral[500],
					},
					[`label${MUI_FOCUS_CLASS}`]: {
						color: colorsTheme.color.brand.neutral[500],
					},
					".MuiFormHelperText-root": {
						color: colorsTheme.color.brand.neutral[500],
					},
					".Mui-error input": {
						color: colorsTheme.color.semantic.error,
					},
				},
			},
		},
	},
};
