import {makeAutoObservable, action, reaction, when} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {Empty} from "data/types/generics";
import type {IChecksums, IChecksumsStore} from "data/stores/checksums/checksums.store";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {isNumber, defaultTo, noop} from "lodash";
import diff from "object-diff-paths";
import {TournamentStatus} from "data/enums";
import type {IClockStore} from "data/stores/clock/clock.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

export interface IHOCLiveScoreController extends ViewController {
	subscribeLiveScoring: () => void;
	unsubscribeLiveScoring: () => void;
}

const ONE_SEC = 1000;
const ONE_MIN = 60 * ONE_SEC;
const ONE_HOUR = 60 * ONE_MIN;
const ONE_DAY = 24 * ONE_HOUR;

@injectable()
export class HOCLiveScoreController implements IHOCLiveScoreController {
	private _timeout: Empty<NodeJS.Timeout>;
	private _isSubscribed: boolean = false;
	private _reactionDisposer: ReturnType<typeof reaction> | null = null;
	private _startDisposer: ReturnType<typeof reaction> | null = null;

	constructor(
		@inject(Bindings.ChecksumsStore) private _checksumsStore: IChecksumsStore,
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore,
		@inject(Bindings.ClockStore) private _clockStore: IClockStore
	) {
		makeAutoObservable(this);
	}

	private get checksums() {
		return this._checksumsStore.checksums;
	}

	private get _actions(): IChecksumAction {
		const selectedRoundID = this._roundsStore.selectedTournament?.id;

		const tournamentActions = selectedRoundID
			? {
					[selectedRoundID]: this.fetchStats(),
					[`${selectedRoundID}_plays`]: this.fetchPlays(),
			  }
			: {};

		return {
			players: () => this.fetchStats(),
			squads: () => this._squadsStore.fetchSquads(),
			settings: () => this._settingsStore.fetch(),
			rounds: () => {
				return this._roundsStore.fetchRounds();
			},
			clock: () => {
				return this._clockStore.fetch();
			},
			...tournamentActions,
		};
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this._checksumsStore.fetch();
		this._startDisposer = when(
			() => !!this._roundsStore.list.length,
			() => {
				this.start();
			}
		);
	}

	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._timeout) {
			clearTimeout(this._timeout);
		}

		this._reactionDisposer?.();
		this._startDisposer?.();
	}

	@action
	compareChecksums(checksums: IChecksums, prev: IChecksums): void {
		const diffChecksums = prev.rounds ? diff(checksums, prev) : [];
		const unhandledDiffs = diffChecksums.filter((e) => {
			if (!e.length) {
				return false;
			}

			const action = this._actions[e as keyof IChecksums];
			if (action && typeof action === "function") {
				void action();
				return false;
			}

			return true;
		});

		if (unhandledDiffs.length) {
			void this.onAnyUnhandledChange(unhandledDiffs);
		}
	}

	private async onAnyUnhandledChange(keys: string[]) {
		if (keys.some((it) => isNumber(+it))) {
			await this._roundsStore.fetchRounds();
		}
	}

	private async fetchStats() {
		const tournamentId = this._roundsStore.selectedTournament?.id;
		const year = this._roundsStore.selectedYear;
		if (tournamentId && year) {
			await this._matchStatsStore.fetchStatsLive(tournamentId, year).catch(noop);
		}
	}

	private async fetchPlays() {
		const tournamentId = this._roundsStore.selectedTournament?.id;
		const year = this._roundsStore.selectedYear;
		if (tournamentId && year) {
			await this._matchStatsStore.fetchPlaysLive(tournamentId, year).catch(noop);
		}
	}

	start() {
		if (this.timeout) {
			this._timeout = setTimeout(() => {
				if (this._isSubscribed) {
					void this._checksumsStore.fetch();
					this.start();
				}
			}, this.timeout);
		}
	}

	private get timeout() {
		const nextMatch =
			this._roundsStore.nextPlayingTournament || this._roundsStore.nextScheduledTournament;
		const lastMatch = this._roundsStore.lastCompletedTournament;
		const nextMatchDate = defaultTo(nextMatch?.date, "");

		const currentTime = new Date().getTime();
		const timeUntilNextMatch = new Date(nextMatchDate).getTime() - currentTime;

		if (nextMatch?.status === TournamentStatus.Playing) {
			return ONE_SEC;
		}

		const isBeforeMatch = timeUntilNextMatch < 2 * ONE_DAY;

		if (isBeforeMatch && nextMatch?.status === TournamentStatus.Scheduled) {
			return ONE_MIN;
		}

		const timeSinceLastMatch = new Date(lastMatch?.date ?? "").getTime() - currentTime;

		if (Math.abs(timeSinceLastMatch) < 5 * ONE_DAY) {
			return ONE_MIN;
		}

		return 0;
	}

	dispose(): void {
		this.unsubscribeLiveScoring();
	}

	init(param: void): void {
		this._reactionDisposer = reaction(
			() => this.checksums,
			(value, previousValue) => {
				this.compareChecksums(value, previousValue);
			}
		);
	}
}
