import {makeAutoObservable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {UserStatus} from "data/enums";
import {noop} from "lodash";
import {STORAGE_KEY} from "data/constants";

const DAY_MINUTES = 60 * 24;
const timeoutMinutes = Number(
	new URLSearchParams(window.location.search).get("timeoutMinutes") || DAY_MINUTES
);

export interface IHOCUserManagementController extends ViewController {
	get timeLimit(): number;

	get isUserApproved(): boolean;
	get timeStorageKey(): string;

	logoutUser: () => void;
	clearUser: () => void;
	tryToLogout: () => void;
}

@injectable()
export class HOCUserManagementController implements IHOCUserManagementController {
	private _timeLimit = 1000 * 60 * timeoutMinutes;
	private timer = setTimeout(noop, 0);
	private logoutDisposer: ReturnType<typeof reaction> | null = null;
	private _timeStorageKey: string = `${STORAGE_KEY}-storage-time`;

	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	get timeStorageKey() {
		return this._timeStorageKey;
	}

	get timeLimit(): number {
		return this._timeLimit;
	}

	dispose(): void {
		clearTimeout(this.timer);
		this.logoutDisposer?.();
	}

	init(param: void): void {
		this.logoutDisposer = reaction(
			() => this.isUserApproved,
			() => {
				this.tryToLogout();
			},
			{
				fireImmediately: true,
			}
		);
	}

	logoutUser = async () => {
		try {
			await this._userStore.logout();
		} catch (e) {
			this.clearUser();
		}
	};

	clearUser = () => {
		void this._userStore.clear();
	};

	get isUserApproved(): boolean {
		if (!this._userStore.user) {
			return true;
		}
		return this._userStore.user?.status === UserStatus.Approved;
	}

	tryToLogout() {
		clearTimeout(this.timer);
		if (this.isUserApproved) {
			/**
			 * Setting the timeout timer to log out the user after the timeout duration if there are any other actions
			 */
			const time = localStorage.getItem(this.timeStorageKey);
			const dffTime = Date.now() - Number(time);
			/**
			 * If the stored time is not null and the difference in time is greater than the timeout duration
			 */
			if (time && dffTime > this._timeLimit) {
				localStorage.removeItem(this.timeStorageKey);
				void this.logoutUser();
				return;
			}
			localStorage.setItem(this.timeStorageKey, Date.now().toString());
		}

		this.timer = setTimeout(() => void this.logoutUser(), this._timeLimit);
	}
}
