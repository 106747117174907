import {ThemeOptions} from "@mui/material/styles/createTheme";
import {secondaryFont} from "assets/theme/typography.theme";

export const chipTheme: ThemeOptions = {
	components: {
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					padding: "1px 4px",
					color: "#AEAEB2",
					border: "1px solid #AEAEB2",
				},
				label: {
					padding: 0,
					fontFamily: secondaryFont,
					fontWeight: 700,
				},
			},
		},
	},
};
