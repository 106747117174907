import {PaletteColorOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	// allows to rewrite theme options

	/*
	 * Disabled  @typescript-eslint/naming-convention @typescript-eslint/no-empty-interface
	 * as MUI theme override required override interfaces
	 */
	// eslint-disable-next-line
	interface CustomPalette {
		adornment?: PaletteColorOptions;
	}

	// eslint-disable-next-line
	interface Palette extends CustomPalette {}

	// eslint-disable-next-line
	interface PaletteOptions extends CustomPalette {}
}

const commonColors = {
	primary: "#B4262F",
	primaryAction: "#821b21",
	neutral300: "#999999",
	colorPrimary: "#222222",
};

export const colorsTheme = {
	palette: {
		primary: {
			main: commonColors.primary,
		},

		adornment: {
			main: "#FFFFFF",
		},
		link: {
			main: "#FFFFFF",
		},
		pure: {
			main: "#FFFFFF",
		},
	},
	color: {
		brand: {
			primary: commonColors.primary,
			neutral: {
				0: "#FFFFFF",
				25: "#F5F5F5",
				50: "#EBEBEB",
				100: "#D6D6D6",
				200: "#C2C2C2",
				300: commonColors.neutral300,
				400: "#858585",
				500: "#707070",
				600: "#5C5C5C",
				700: "#474747",
				800: "#333333",
				900: "#191919",
			},
		},
		text: {
			primary: commonColors.colorPrimary,
			contrast: "#EBEBEB",
			white: "#FFFFFF",
		},
		semantic: {
			warning: "#FF9800",
			error: "#d32f2f",
			info: "#2196F3",
			success: "#18C971",
		},
		components: {
			button: {
				primary: {
					color: "#ffffff",
					background: commonColors.primary,
					borderColor: commonColors.primary,

					disabledColor: "#ffffff",
					disabledBackground: commonColors.neutral300,
					disabledBorderColor: commonColors.neutral300,

					hoverColor: "#fff",
					hoverBackground: commonColors.primaryAction,
					hoverBorderColor: commonColors.primaryAction,
				},
				secondary: {
					color: commonColors.primary,
					background: "transparent",
					borderColor: commonColors.primary,

					disabledColor: commonColors.neutral300,
					disabledBackground: "transparent",
					disabledBorderColor: commonColors.neutral300,

					hoverColor: commonColors.primaryAction,
					hoverBackground: "transparent",
					hoverBorderColor: commonColors.primaryAction,
				},
				adornment: {
					color: "#525252",
					background: "transparent",
					borderColor: "transparent",

					disabledColor: "#525252",
					disabledBackground: "transparent",
					disabledBorderColor: "transparent",

					hoverColor: "#525252",
					hoverBackground: "transparent",
					hoverBorderColor: "transparent",
				},
				link: {
					color: commonColors.primary,
					background: "transparent",
					borderColor: "transparent",

					disabledColor: "#525252",
					disabledBackground: "transparent",
					disabledBorderColor: "transparent",

					hoverColor: commonColors.primaryAction,
					hoverBackground: "transparent",
					hoverBorderColor: "transparent",
				},
				toggle: {
					color: commonColors.colorPrimary,
					background: "#FFFFFF",
					borderColor: "#FFFFFF",

					disabledColor: "#ffffff",
					disabledBackground: commonColors.neutral300,
					disabledBorderColor: commonColors.neutral300,

					hoverColor: commonColors.colorPrimary,
					hoverBackground: "#D6D6D6",
					hoverBorderColor: "#D6D6D6",
				},
			},
			header: {
				background: "#191919",
				color: "#FFFFFF",
				avatarBackground: "#C2C2C2",
				avatarColor: "#FFFFFF",
			},
			subheader: {
				background: "#333333",
			},
			horizontalSchedule: {
				dark: {
					background: "#333333",
					backgroundSelected: "#545454",
					color: "#FFFFFF",
					secondaryColor: "rgba(255, 255, 255, 0.5)",
				},
				white: {
					background: "#FFFFFF",
					backgroundSelected: "#FFFFFF",
					color: "#222222",
					secondaryColor: "rgba(18, 18, 18, 0.3)",
				},
				primary: {
					background: commonColors.primary,
					backgroundSelected: commonColors.primary,
					color: "#FFFFFF",
					secondaryColor: "rgba(255, 255, 255, 0.5)",
				},
			},
			table: {
				background: "#FFFFFF",
				color: "#222222",
				activeRowBackground: "rgba(255, 152, 0, 0.15)",
				secondaryRowBackground: "rgba(18, 18, 18, 0.03)",
				borderColor: "rgba(18, 18, 18, 0.15)",
				hoverRowColor: "rgba(18, 18, 18, 0.15)",
			},
			fixtureCard: {
				headerBackground: "#222222",
				headerColor: "#FFFFFF",
				headerColorSecondary: "#AEAEB2",
				sumBlockBackground: "#EAEAEA",
				sumBlockBorderColor: "#FFB610",
				loseColor: "#707070",
			},
			fixtureInfo: {
				timeoutsUsed: "#dedede",
				timeoutsUnUsed: commonColors.colorPrimary,
			},
			chip: {
				live: {
					background: "#FF0000",
					color: "#FFFFFF",
				},
			},
		},
	},
};
