import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {ISquad, ITournament, ITournamentSquad} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";

interface IParams {
	tournamentId: number;
}

export interface IFixtureInfoTableController extends ViewController<IParams> {
	get tournament(): Empty<ITournament>;

	get squadScoreKeys(): {label: string; key: string}[];

	get tournamentHomeSquad(): Empty<ITournamentSquad>;

	get tournamentAwaySquad(): Empty<ITournamentSquad>;

	get homeSquadName(): Empty<string>;

	get awaySquadName(): Empty<string>;

	get quarter(): string;

	updateParams: (params: IParams) => void;
}

@injectable()
export class FixtureInfoTableController implements IFixtureInfoTableController {
	@observable private _tournamentId: Empty<number>;

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	private _squadScoreKeys = [
		{
			label: "1",
			key: "Q1Score",
		},
		{
			label: "2",
			key: "Q2Score",
		},
		{
			label: "3",
			key: "Q3Score",
		},
		{
			label: "4",
			key: "Q4Score",
		},
		{
			label: "OT",
			key: "OTScore",
		},
		{
			label: "T",
			key: "score",
		},
	];

	get squadScoreKeys() {
		if (this.matchHasOTScore) {
			return this._squadScoreKeys;
		}
		return this._squadScoreKeys.filter((score) => score.key !== "OTScore");
	}

	get tournament(): Empty<ITournament> {
		return this._roundsStore.getTournamentById(this._tournamentId);
	}

	get tournamentHomeSquad(): Empty<ITournamentSquad> {
		return this.tournament?.homeSquad;
	}

	get tournamentAwaySquad(): Empty<ITournamentSquad> {
		return this.tournament?.awaySquad;
	}

	get awaySquadName(): Empty<string> {
		return this.awaySquad?.abbreviation;
	}

	get homeSquadName(): Empty<string> {
		return this.homeSquad?.abbreviation;
	}

	get quarter(): string {
		// TODO: Add after BE implementation
		return "";
	}

	protected get homeSquad(): Empty<ISquad> {
		return this._squadsStore.getSquadById(this.tournamentHomeSquad?.id);
	}

	protected get awaySquad(): Empty<ISquad> {
		return this._squadsStore.getSquadById(this.tournamentAwaySquad?.id);
	}

	private get matchHasOTScore() {
		return Boolean(this.tournamentHomeSquad?.OTScore || this.tournamentAwaySquad?.OTScore);
	}
	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._tournamentId = param.tournamentId;
	}

	updateParams = (params: IParams) => {
		this._tournamentId = params.tournamentId;
	};
}
