import {makeAutoObservable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {IPlay, IScoringPlay} from "data/types/matchStats";
import {defaultTo, takeRight, throttle} from "lodash";
import {ISquad} from "data/types/entities";
import {Empty, WithRowIndex} from "data/types/generics";
import {SquadUtils} from "data/utils/squad";
import {useRef, RefObject} from "react";
import type {IHighlightStore} from "data/stores/highlight/highlight.store";
import {LIVE_CHANGE_CLASS} from "data/constants";
import {TournamentStatus, SideOfPitch, FixtureTabs} from "data/enums";
import {PlayerUtils} from "data/utils/player";

type ILeaderFields = {
	homeScore: number;
	awayScore: number;
	isHomeLeader: boolean;
	isAwayLeader: boolean;
	isBold: boolean;
	isScoring: boolean;
	pid: number;
};

interface ISquadAbbrs {
	home: string;
	away: string;
}

export interface IPlayListController extends ViewController {
	get isLoading(): boolean;

	get tableRef(): RefObject<HTMLDivElement>;

	get plays(): WithRowIndex<IPlay>[];

	get allPlays(): IPlay[];

	get scoringPlays(): WithRowIndex<IScoringPlay>[];

	get allScoringPlays(): IScoringPlay[];

	get squadAbbreviations(): ISquadAbbrs;

	get isRoundWithAllStats(): boolean;

	buildSituationString(play: IPlay | IScoringPlay): string;

	getSquad(id: number): Empty<ISquad>;

	isFirstTeam(id: number): boolean;

	highlightClass(rowIndex: number, type: string): string;

	isDarken(play: IScoringPlay): boolean;

	scrollTable(isAuto?: boolean): void;
}

@injectable()
export class PlayListController implements IPlayListController {
	private _tableRef = useRef<HTMLDivElement>(null);
	private _scoringPlaysDisposer: ReturnType<typeof reaction> | null = null;
	private _playsDisposer: ReturnType<typeof reaction> | null = null;
	private _scrollTimeout: ReturnType<typeof setTimeout> | null = null;
	private _hasScrolledUp: boolean = false;
	private _tmpScrollPosition: null | number = null;

	constructor(
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.HighlightStore) private _highlightStore: IHighlightStore
	) {
		makeAutoObservable(this);
	}

	get tableRef() {
		return this._tableRef;
	}

	get isLoading() {
		return this._matchStatsStore.isLoading;
	}

	get plays() {
		return takeRight(
			this._matchStatsStore.plays.map((it, rowIndex) => ({
				...it,
				rowIndex,
			})),
			5
		);
	}

	get allPlays() {
		return this._matchStatsStore.plays;
	}

	get scoringPlays() {
		return takeRight(
			this.allScoringPlays.map((it, rowIndex) => ({
				...it,
				rowIndex,
			})),
			5
		);
	}

	get allScoringPlays() {
		return this._matchStatsStore.scoringPlays;
	}

	get squadAbbreviations(): ISquadAbbrs {
		const tournament = this._roundsStore.selectedTournament;

		return {
			home: SquadUtils.getAbbreviation(this.getSquad(tournament?.homeSquad.id)),
			away: SquadUtils.getAbbreviation(this.getSquad(tournament?.awaySquad.id)),
		};
	}

	get isRoundWithAllStats(): boolean {
		return !this._roundsStore.isRoundWithPrevStats;
	}

	public buildSituationString(play: IPlay | IScoringPlay) {
		const {
			teamInPossession,
			downNumber = 0,
			yardsToGo = 0,
			scrimmageYard = 0,
			sideOfPitch,
		} = play;

		const squad = this._squadsStore.getSquadById(teamInPossession);
		const squadAbbrs = this.squadAbbreviations;
		const sideOfPitchSquad =
			sideOfPitch === SideOfPitch.Home ? squadAbbrs.home : squadAbbrs.away;
		const sidePitchFirstLetter = sideOfPitch?.length ? sideOfPitchSquad[0] : "";
		if (!squad) {
			return "-";
		}

		const abbreviation = SquadUtils.getAbbreviation(squad).toUpperCase();

		return `${abbreviation}-${defaultTo(downNumber, 0)}-${defaultTo(
			yardsToGo,
			0
		)}-${sidePitchFirstLetter}${scrimmageYard}`;
	}

	public getSquad(id?: number) {
		return this._squadsStore.getSquadById(id);
	}

	public isFirstTeam(id: number) {
		const tournament = this._roundsStore.selectedTournament;
		return PlayerUtils.isFirstSquad({squadId: id}, tournament);
	}

	public highlightClass(index: number, type: string) {
		const keySearch = `${type}.[${index}]`;
		return this._highlightStore.isHighlight(keySearch) ? LIVE_CHANGE_CLASS : "";
	}

	public isDarken(play: IScoringPlay) {
		return !(Number(play.quarter) % 2);
	}

	public dispose(): void {
		if (this._scrollTimeout) {
			clearTimeout(this._scrollTimeout);
		}
		this._scoringPlaysDisposer?.();
		this._playsDisposer?.();
		this.disposeScrollUp();
	}

	public init(param: void): void {
		this.initScroll();
	}

	public scrollTable(isAuto?: boolean) {
		if (this._roundsStore.selectedTournament?.status !== TournamentStatus.Complete) {
			this._scrollTimeout = setTimeout(() => {
				const current = this.tableRef.current;
				if (!current) {
					return;
				}
				current.scrollTo({
					top: current.scrollHeight,
					left: 0,
					behavior: isAuto ? "auto" : "smooth",
				});
				this._scoringPlaysDisposer?.();
			}, 200);
		}
	}

	private initScroll() {
		this._scoringPlaysDisposer = reaction(
			() => this._matchStatsStore.scoringPlays,
			() => {
				if (!this._hasScrolledUp) {
					this.scrollTable();
				}
			}
		);

		this._playsDisposer = reaction(
			() => this._matchStatsStore.plays,
			() => {
				if (!this._hasScrolledUp) {
					this.scrollTable();
				}
			}
		);

		this.initScrollUp();
	}

	private initScrollUp() {
		this.tableRef?.current?.addEventListener("scroll", this.scrollUpListener);
	}

	private disposeScrollUp() {
		this.tableRef?.current?.removeEventListener("scroll", this.scrollUpListener);
	}

	private scrollUpListener = throttle((e: Event) => {
		const elem = e.target as Element;
		if (this._tmpScrollPosition) {
			if (this._tmpScrollPosition > elem.scrollTop) {
				this._hasScrolledUp = true;
				this._highlightStore.disableDiffForTab(FixtureTabs.PlayList);
			}
			if (elem.scrollTop === elem.scrollHeight - elem.clientHeight) {
				this._hasScrolledUp = false;
				this._highlightStore.enableDiffForTab(FixtureTabs.PlayList);
				this._highlightStore.calculateDiffForTab(FixtureTabs.PlayList);
			}
		}
		this._tmpScrollPosition = elem.scrollTop;
	}, 200);

	private buildLeaderChangeFlags = <T extends ILeaderFields, TD extends T = T>(
		accumulator: T[],
		currentValue: TD,
		index: number
	): T[] => {
		const prevValue = accumulator[index - 1];
		const isHomeWinner = currentValue.homeScore > currentValue.awayScore;
		const isAwayWinner = currentValue.homeScore < currentValue.awayScore;

		/**
		 * If no prevValue (first score) we don't bold the value
		 */
		if (!prevValue) {
			return [
				...accumulator,
				{
					...currentValue,
					isHomeLeader: isHomeWinner,
					isAwayLeader: isAwayWinner,
					isBold: false,
				},
			];
		}

		/**
		 * If draw we keep the prev values and don't bold or if not scoring play
		 */
		if (currentValue.homeScore === currentValue.awayScore || !currentValue.isScoring) {
			return [
				...accumulator,
				{
					...currentValue,
					isHomeLeader: prevValue.isHomeLeader,
					isAwayLeader: prevValue.isAwayLeader,
					isBold: false,
				},
			];
		}

		/**
		 * Check if winner changed for both scores
		 * yes - bold the winner
		 * no - keep current winner, don't bold
		 */
		const isWinnerChanged =
			isHomeWinner !== prevValue.isHomeLeader && isAwayWinner !== prevValue.isAwayLeader;

		return [
			...accumulator,
			{
				...currentValue,
				isHomeLeader: isHomeWinner,
				isAwayLeader: isAwayWinner,
				isBold: isWinnerChanged,
			},
		];
	};
}
