import {ThemeOptions} from "@mui/material/styles/createTheme";
import {colorsTheme} from "assets/theme/colors.theme";

export const tabsTheme: ThemeOptions = {
	components: {
		MuiTabs: {
			defaultProps: {
				indicatorColor: "primary",
				variant: "scrollable",
				scrollButtons: false,
			},
			styleOverrides: {
				root: {
					borderBottom: "1px solid #C2C2C2",
				},
				indicator: {
					height: "4px",
				},
				scroller: {
					display: "flex",
					justifyContent: "center",
					"@media (max-width: 900px)": {
						display: "block",
					},
				},
			},
		},
		MuiTab: {
			defaultProps: {
				wrapped: false,
			},
			styleOverrides: {
				root: {
					fontFamily: '"Hind", sans-serif',
					fontWeight: "400",
					padding: "0 20px",
					".MuiTouchRipple-root": {
						color: colorsTheme.color.brand.primary,
					},
					"&.Mui-selected": {
						color: colorsTheme.color.text.primary,
					},
				},
			},
		},
	},
};
