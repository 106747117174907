import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {Bindings} from "data/constants/bindings";
import type {
	ILoginPayload,
	IRegisterPayload,
	IResetPasswordPayload,
	IUser,
} from "data/types/entities";
import {AxiosError} from "axios";

export interface IUserStore {
	get user(): IUser | undefined;

	get fullName(): string;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	get isAfterRegister(): boolean;

	fetchUser(): Promise<void>;

	login(payload: ILoginPayload): Promise<void>;

	register(payload: IRegisterPayload): Promise<void>;

	logout(): Promise<void>;

	forgotPasswordRequest(email: string): Promise<void>;

	resetPasswordRequest(payload: IResetPasswordPayload): Promise<void>;

	clear(): void;
}

@injectable()
export class UserStore implements IUserStore {
	constructor(@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider) {
		makeAutoObservable(this);
	}

	@observable private _user?: IUser = undefined;

	get user() {
		return this._user;
	}

	set user(user: IUser | undefined) {
		this._user = user;
	}

	@observable private _isAuthorized = false;

	get isAuthorized(): boolean {
		return this._isAuthorized;
	}

	@observable private _wasLoggedOut = false;

	get wasLoggedOut(): boolean {
		return this._wasLoggedOut;
	}

	@observable private _isAfterRegister = false;

	get isAfterRegister(): boolean {
		return this._isAfterRegister;
	}

	@computed get fullName() {
		if (!this.user) {
			return "";
		}

		const {firstName, lastName} = this.user;
		return `${firstName} ${lastName}`;
	}

	@action
	async fetchUser(): Promise<void> {
		try {
			const response = await this._authApi.fetchUser();
			const {user} = response.data.success;

			runInAction(() => {
				this.user = user;
				this._isAuthorized = true;
				this._wasLoggedOut = false;
				this._isAfterRegister = false;
			});
		} catch (e) {
			const error = e as AxiosError;
			if (error.response?.status === 402) {
				// Ignore this error. It's expected behavior
				return;
			}
			throw e;
		}
	}

	@action
	async login(payload: ILoginPayload): Promise<void> {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this.user = user;
			this._isAuthorized = true;
			this._wasLoggedOut = false;
			this._isAfterRegister = false;
		});
	}

	@action
	async register(payload: IRegisterPayload): Promise<void> {
		const response = await this._authApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this.user = user;
			this._isAuthorized = true;
			this._wasLoggedOut = false;
			this._isAfterRegister = true;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this.user = undefined;
			this._isAuthorized = false;
			this._wasLoggedOut = true;
		});
	}

	@action
	clear() {
		runInAction(() => {
			this.user = undefined;
			this._isAuthorized = false;
			this._wasLoggedOut = true;
		});
	}

	async forgotPasswordRequest(email: string): Promise<void> {
		try {
			await this._authApi.forgotPasswordRequest(email);

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	async resetPasswordRequest(payload: IResetPasswordPayload): Promise<void> {
		try {
			await this._authApi.resetPasswordRequest(payload);

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
