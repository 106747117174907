import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";

import {ReactComponent as Logo} from "assets/img/icons/logo.svg";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/header/header.controller";
import {Bindings} from "data/constants/bindings";
import {NavLink} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {HeaderAccount} from "views/components/header_account/header_account.component";
import {Subheader} from "views/components/sub_header/subheader.component";
import {breakpoints} from "assets/theme/theme";
import {Typography} from "@mui/material";

const HeaderElement = styled.div``;

const HeaderTopBlock = styled.div`
	padding: 12px 16px;
	background: ${({theme}) => theme.color.components.header.background};
	border-bottom: 1px solid ${({theme}) => theme.color.brand.primary};

	@media screen and (max-width: ${breakpoints.mobile}) {
		padding: 10px;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.divider {
		height: 100%;
		min-width: 1px;
		width: 1px;
		background: ${({theme}) => theme.color.text.white};
	}

	@media screen and (max-width: ${breakpoints.mobile}) {
		.divider {
			height: 22px;
		}
	}
`;

const LogoWrapper = styled(NavLink)`
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const LogoBlock = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: ${({theme}) => theme.color.text.white};
	text-transform: uppercase;

	.app-name {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;
		height: 34px;
		padding-top: 2px;

		.caption {
			position: absolute;
			left: 0;
			bottom: -10px;
		}
	}

	@media screen and (max-width: ${breakpoints.mobile}) {
		h3 {
			font-size: 20px;
			line-height: 31px;
		}

		svg {
			width: 30px;
		}

		.caption {
			font-size: 10px;
		}

		.divider {
			height: 22px;
		}

		.app-name {
			padding-top: 0;
		}
	}
`;

export const Header: React.FC = observer(() => {
	const {logoLink, isAuthorized, hasAccess} = useViewController<IHeaderController>(
		Bindings.HeaderController
	);
	return (
		<HeaderElement>
			<HeaderTopBlock>
				<HeaderContainer>
					<LogoBlock>
						<LogoWrapper to={logoLink}>
							<Logo />

							<div className="app-name">
								<Typography variant="h3">Connect</Typography>
								<div className="divider" />
								<Typography variant="h3">Game stats</Typography>

								<Typography variant="caption" className="caption">
									V 7.0.1
								</Typography>
							</div>
						</LogoWrapper>
					</LogoBlock>

					<HeaderAccount />
				</HeaderContainer>
			</HeaderTopBlock>

			<Exist when={isAuthorized && hasAccess}>
				<Subheader />
			</Exist>
		</HeaderElement>
	);
});
