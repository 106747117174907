import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {ISuccessModalPayload} from "data/types/modal";

export interface IModalSuccessController extends ViewController {
	closeModal: () => void;

	get isModalOpen(): boolean;

	get modalData(): ISuccessModalPayload;
}

@injectable()
export class ModalSuccessController implements IModalSuccessController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	get isModalOpen(): boolean {
		return this._modalsStore.modal === ModalType.SUCCESS;
	}

	get modalData(): ISuccessModalPayload {
		return (this._modalsStore.modalContent ?? {}) as ISuccessModalPayload;
	}

	public closeModal = () => {
		this.closeCallback();
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	protected closeCallback() {
		if (this.modalData.closeFunction && typeof this.modalData.closeFunction === "function") {
			this.modalData.closeFunction();
		}
	}
}
