import React, {PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IHOCMainController} from "views/components/hoc/hoc_main/hoc_main.controller";
import {PagePreloader} from "views/components/preloader";
import {ModalSuccess} from "views/components/modals/modal_success/modal_success.component";

export const HOCMain: React.FC<PropsWithChildren> = observer(({children}) => {
	const {isLoading} = useViewController<IHOCMainController>(Bindings.HOCMainController);
	if (isLoading) {
		return <PagePreloader />;
	}

	return (
		<React.Fragment>
			<React.Fragment>{children}</React.Fragment>
			<ModalSuccess />
		</React.Fragment>
	);
});
