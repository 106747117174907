import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {AxiosError} from "axios";
import {ModalType} from "data/enums";

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

export interface ILoginController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<ILoginForm>) => void;
	resetFormErrors: () => void;
	togglePasswordVisibility: () => void;
	openForgotPassword: () => void;

	get error(): string | undefined;

	get isPasswordVisible(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class LoginController implements ILoginController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable _error?: string = undefined;

	get error() {
		return this._error;
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		this._isLoading = true;
		this.resetFormErrors();

		this._userStore
			.login({
				email: email.value,
				password: password.value,
			})
			.catch(this.onLoginError.bind(this))
			.finally(this.onLoginFinally.bind(this));
	};

	@action
	public resetFormErrors = () => {
		this._error = undefined;
	};

	@action
	public togglePasswordVisibility = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	public openForgotPassword = (): void => {
		this._modalsStore.showModal(ModalType.FORGOT_PASSWORD);
	};

	@action
	private onLoginError = (error: AxiosError<{errors: {message: string}[]}>) => {
		this._error = error.response?.data?.errors[0]?.message || error.message;
		this._isLoading = false;
	};

	@action
	private onLoginFinally = () => {
		this._isLoading = false;
	};
}
