import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Button} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IHeaderAccountController} from "views/components/header_account/header_account.controller";
import {Bindings} from "data/constants/bindings";
import {breakpoints} from "assets/theme/theme";
import {NavLink} from "react-router-dom";

const AuthBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 12px;
	color: ${({theme}) => theme.color.text.white};
	font-weight: 600;
	text-transform: uppercase;
	height: 34px;

	a,
	button {
		color: ${({theme}) => theme.color.text.white};

		&:hover,
		&:active,
		&:focus {
			color: ${({theme}) => theme.color.text.white};
		}
	}

	@media screen and (max-width: ${breakpoints.mobile}) {
		a {
			font-size: 14px;
		}
	}
`;

export const HeaderAccount: React.FC = observer(() => {
	const {isAuthorized, logout} = useViewController<IHeaderAccountController>(
		Bindings.HeaderAccountController
	);

	if (!isAuthorized) {
		return (
			<AuthBlock>
				<NavLink to="/login">Login</NavLink>
				<div className="divider" />
				<NavLink to="/register">Register</NavLink>
			</AuthBlock>
		);
	}

	return (
		<AuthBlock>
			<Button color="link" onClick={logout}>
				Logout
			</Button>
		</AuthBlock>
	);
});
