import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {ITournament} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {TournamentStatus, ClockType} from "data/enums";
import {DateTime, Duration} from "luxon";
import {LABEL_DATE_FORMAT} from "data/constants";
import {buildOrdinalNumber} from "data/utils/helpers";
import type {IClockStore} from "data/stores/clock/clock.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";

interface IParams {
	tournamentId: number;
	small?: boolean;
}

enum PeriodEnum {
	PRE_MATCH = "prematch",
	HALF_TIME = "halftime",
	OVER_TIME = "overtime",
}

interface IGameTimeType {
	isClockRunning: boolean;
	totalGameSeconds: number;
	utcTimestamp: string;
}

export interface IMatchStatusController extends ViewController<IParams> {
	get status(): TournamentStatus;

	get date(): string;

	get isLive(): boolean;

	get isInterrupted(): boolean;

	period: (withDash: boolean) => string;

	update(params: IParams): void;
}

@injectable()
export class MatchStatusController implements IMatchStatusController {
	@observable protected _tournamentId: Empty<number>;
	@observable protected _smallLabels: boolean = false;
	@observable protected _gameSeconds: number = 0;
	@observable private _disposers: ReturnType<typeof reaction>[] = [];
	@observable private _intervalClock: ReturnType<typeof setInterval> | null = null;

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ClockStore) private _clocksStore: IClockStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		this._disposers.forEach((dispose) => dispose());
		this.stopTimer();
	}

	init(param: IParams): void {
		this._tournamentId = param.tournamentId;
		this._smallLabels = !!param.small;
		this._disposers = [
			reaction(
				() => [this.matchClock, this._settingsStore.clockType],
				([matchClock, clockType]) => {
					if (matchClock && clockType === ClockType.RUNNING) {
						this.initCountdown();
					}
				},
				{fireImmediately: true}
			),
			reaction(
				() => this._settingsStore.clockType,
				(clockType) => {
					if (clockType === ClockType.STATIC) {
						this.stopTimer();
					}
				}
			),
		];
	}

	get status() {
		return this.tournament?.status || TournamentStatus.Scheduled;
	}

	get date() {
		if (!this.tournament) {
			return "-";
		}
		return DateTime.fromISO(this.tournament?.date).toFormat(LABEL_DATE_FORMAT);
	}

	get isLive(): boolean {
		if (this.tournament) {
			const {status} = this.tournament;
			return status === TournamentStatus.Playing;
		}

		return false;
	}

	get isInterrupted(): boolean {
		return !!this.tournament?.isInterrupted;
	}

	protected get tournament(): Empty<ITournament> {
		return this._roundsStore.getTournamentById(this._tournamentId);
	}

	public period = (withDash: boolean) => {
		if (!this.tournament) {
			return "-";
		}
		const {activePeriod} = this.tournament;
		const label = this.phaseLabel(withDash);

		if (label) {
			return label;
		}

		const clockTime = this.getTime ? `${this.getTime} - ` : "";

		return `${clockTime}${buildOrdinalNumber(activePeriod)}`;
	};

	private get getTime() {
		if (this._settingsStore.clockType === ClockType.STATIC || this.tournament?.isPreMatch) {
			return this.staticClock;
		}

		return this.syncClock;
	}

	private get staticClock() {
		const clock = this.tournament?.clock;

		if (!clock) {
			return null;
		}

		const splitClock = clock.split(":");
		const [hours, ...rest] = splitClock;

		if (splitClock.length === 2 || parseInt(hours)) {
			return clock;
		}

		return rest.join(":");
	}

	private get syncClock() {
		const durations = Duration.fromMillis(this._gameSeconds * 1000);
		return durations.toFormat("mm:ss");
	}

	private initCountdown() {
		const matchClock = this.matchClock;
		if (!matchClock || !matchClock.clock) {
			this.stopTimer();
			return;
		}

		if (!matchClock.isRunning) {
			this._gameSeconds = this.calculateGameSeconds();
			return;
		}

		this._intervalClock = setInterval(() => {
			this.setGameSeconds();
		}, 333);
	}

	private setGameSeconds() {
		const matchClock = this.matchClock;

		if (!matchClock?.clock || !matchClock.lastUpdatedUtc) {
			return null;
		}
		this._gameSeconds = this.calculateCurrentGameTime({
			isClockRunning: !!matchClock.isRunning,
			totalGameSeconds: this.calculateGameSeconds(),
			utcTimestamp: matchClock.lastUpdatedUtc,
		});

		if (!this._gameSeconds) {
			this.stopTimer();
		}
	}

	private get matchClock() {
		if (!this._tournamentId) {
			return null;
		}
		return this._clocksStore.matchClocks[this._tournamentId];
	}

	private phaseLabel(withDash: boolean) {
		const clockTime = this.getTime;

		const dash = withDash ? " - " : " ";
		const time = clockTime ? `${dash}${clockTime}` : "";

		const labels = !this._smallLabels
			? {
					[PeriodEnum.PRE_MATCH]: "PREMATCH",
					[PeriodEnum.HALF_TIME]: "HALFTIME",
					[PeriodEnum.OVER_TIME]: "OVERTIME",
			  }
			: {
					[PeriodEnum.PRE_MATCH]: "PRE",
					[PeriodEnum.HALF_TIME]: "HT",
					[PeriodEnum.OVER_TIME]: "OT",
			  };

		if (this.tournament?.isPreMatch) {
			return `${labels[PeriodEnum.PRE_MATCH]}${time}`;
		}
		if (this.tournament?.isHalfTime) {
			return `${labels[PeriodEnum.HALF_TIME]}${time}`;
		}
		if (this.tournament?.isOverTime) {
			return `${labels[PeriodEnum.OVER_TIME]}`;
		}
	}

	update(params: IParams) {
		this._tournamentId = params.tournamentId;
		this._smallLabels = !!params.small;
	}

	calculateCurrentGameTime = (gameTime: IGameTimeType): number => {
		const {isClockRunning, totalGameSeconds, utcTimestamp} = gameTime;

		if (!isClockRunning) {
			return totalGameSeconds;
		}

		const utcTimestampDate = new Date(new Date(utcTimestamp));
		const diffNow = new Date().getTime() - utcTimestampDate.getTime();
		const timePassedSinceServerLastReceivedGameTimeMs = Math.max(diffNow, 0);
		const newlySynchronizedGameTimeInMilliseconds =
			totalGameSeconds * 1000 - timePassedSinceServerLastReceivedGameTimeMs;
		if (newlySynchronizedGameTimeInMilliseconds < 0) {
			return 0;
		}
		return Math.round(newlySynchronizedGameTimeInMilliseconds / 1000);
	};

	private calculateGameSeconds() {
		const matchClock = this.matchClock;
		if (!matchClock || !matchClock.clock) {
			return 0;
		}

		const splitClock = matchClock.clock.split(":");
		const [hours, minutes, seconds] = splitClock;
		return Number(hours) * 60 + Number(minutes) * 60 + Number(seconds);
	}

	private stopTimer() {
		if (this._intervalClock) {
			clearInterval(this._intervalClock);
		}
	}
}
