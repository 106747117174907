import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import type {IHighlightStore} from "data/stores/highlight/highlight.store";
import {LIVE_CHANGE_CLASS} from "data/constants";
import {Empty} from "data/types/generics";

interface IParams {
	playerID?: number;
	squadId?: number;
	driveRow?: number;
	stats: string | string[];
}

export interface ITableCellHighlightController extends ViewController {
	isHighlight(params: IParams): Empty<string>;
}

@injectable()
export class TableCellHighlightController implements ITableCellHighlightController {
	constructor(
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.HighlightStore) private _highlightStore: IHighlightStore
	) {
		makeAutoObservable(this);
	}

	private isHighlightedPlayerStatClass(id: number, keys: string | string[]) {
		const properties = Array.isArray(keys) ? keys : [keys];

		const isActive = properties.some((key) => {
			return this._highlightStore.isHighlight(`players.[${id}].stats.${key}`);
		});

		return isActive ? LIVE_CHANGE_CLASS : "";
	}

	private isHighlightedSquadStatClass(squadId: number, keys: string | string[]) {
		const squadIndex = this._matchStatsStore.squads.findIndex((it) => it.squadId === squadId);

		const properties = Array.isArray(keys) ? keys : [keys];

		const isActive = properties.some((key) => {
			return this._highlightStore.isHighlight(`squads.[${squadIndex}].stats.${key}`);
		});

		return isActive ? LIVE_CHANGE_CLASS : "";
	}

	private isHighlightedDriveStatClass(driveRowNumber: number, keys: string | string[]) {
		const properties = Array.isArray(keys) ? keys : [keys];

		const isActive = properties.some((key) => {
			return this._highlightStore.isHighlight(`drives.[${driveRowNumber}].${key}`);
		});

		return isActive ? LIVE_CHANGE_CLASS : "";
	}

	isHighlight({playerID, squadId, driveRow, stats}: IParams) {
		if (playerID) {
			return this.isHighlightedPlayerStatClass(playerID, stats);
		}

		if (squadId) {
			return this.isHighlightedSquadStatClass(squadId, stats);
		}
		if (driveRow) {
			return this.isHighlightedDriveStatClass(driveRow, stats);
		}
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
