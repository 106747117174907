import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {find} from "lodash";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ISquadMatchStats} from "data/types/matchStats";
import React from "react";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {SquadUtils} from "data/utils/squad";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import {toFixed, toTimeFormat} from "data/utils/helpers";
import {DEFAULT_SQUAD} from "data/constants";
import {Empty} from "data/types/generics";

interface IStats {
	label: string;
	keys: string[];
	formula: string;
	value: (matchStats: ISquadMatchStats) => React.ReactNode;
}

export interface ITeamStatsController extends ViewController {
	get tableStats(): IStats[];

	get stats(): {
		homeStats?: ISquadMatchStats;
		awayStats?: ISquadMatchStats;
	};

	get homeID(): Empty<number>;
	get awayID(): Empty<number>;

	get squadTitles(): {
		home: string;
		away: string;
	};
}

@injectable()
export class TeamStatsController implements ITeamStatsController {
	constructor(
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	get homeID() {
		const tournament = this._roundsStore.selectedTournament;
		return tournament?.homeSquad.id;
	}

	get season() {
		return this._roundsStore.selectedYear;
	}

	get awayID() {
		const tournament = this._roundsStore.selectedTournament;
		return tournament?.awaySquad.id;
	}

	get stats() {
		const tournament = this._roundsStore.selectedTournament;
		const homeId = tournament?.homeSquad.id;
		const awayId = tournament?.awaySquad.id;
		const statsObject = this._matchStatsStore.squads;

		const homeStats =
			find(statsObject, (squadStats) => squadStats.squadId === homeId) || DEFAULT_SQUAD;
		const awayStats =
			find(statsObject, (squadStats) => squadStats.squadId === awayId) || DEFAULT_SQUAD;

		if (this._matchStatsStore.isLoading) {
			return {
				homeStats: undefined,
				awayStats: undefined,
			};
		}

		return {
			homeStats: {
				...DEFAULT_SQUAD.stats,
				...homeStats,
			},
			awayStats: {
				...DEFAULT_SQUAD.stats,
				...awayStats,
			},
		};
	}

	get squadTitles() {
		const tournament = this._roundsStore.selectedTournament;
		const homeId = tournament?.homeSquad.id;
		const awayId = tournament?.awaySquad.id;
		const homeSquad = this._squadsStore.getSquadById(homeId);
		const awaySquad = this._squadsStore.getSquadById(awayId);

		return {
			home: SquadUtils.getAbbreviation(homeSquad),
			away: SquadUtils.getAbbreviation(awaySquad),
		};
	}

	get tableStats() {
		return [
			{
				keys: ["firstDowns"],
				formula: "firstDowns",
				label: "First Downs",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.firstDowns || 0;
				},
			},
			{
				keys: ["secondDownsConversions", "secondDownsAttempted"],
				label: "2nd Down Conv",
				formula: "secondDownsConversions-secondDownsAttempted",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					const {secondDownsConversions = 0, secondDownsAttempted = 0} = matchStats.stats;
					return `${secondDownsConversions}-${secondDownsAttempted}`;
				},
			},
			{
				keys: ["offenseYards"],
				label: "Net Offence",
				formula: "offenseYards",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					const {offenseYards = 0} = matchStats.stats;
					return offenseYards;
				},
			},
			{
				keys: ["plays"],
				label: "Off Plays",
				formula: "plays",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.plays || 0;
				},
			},
			{
				keys: ["playYardsAverage"],
				label: "Avg Gain",
				formula: "playYardsAverage",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					return toFixed(matchStats.stats.playYardsAverage);
				},
			},
			{
				keys: ["rushingYards"],
				label: "Rushing Yds",
				formula: "rushingYards",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.rushingYards || 0;
				},
			},
			{
				keys: ["passesSucceededYards"],
				label: "Passing Yds",
				formula: "passesSucceededYards",
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.passesSucceededYards || 0;
				},
			},
			{
				keys: [
					"penaltiesChargedDefense",
					"penaltiesChargedOffense",
					"penaltiesForLossYards",
				],
				formula:
					"penaltiesChargedDefense + penaltiesChargedOffense (penaltiesForLossYards)",
				label: "Penalties (Yds)",
				value: (matchStats: ISquadMatchStats) => {
					const {
						penaltiesChargedDefense = 0,
						penaltiesChargedOffense = 0,
						penaltiesForLossYards = 0,
					} = matchStats.stats;
					return `${
						penaltiesChargedDefense + penaltiesChargedOffense
					}(${penaltiesForLossYards})`;
				},
			},
			{
				keys: ["turnovers"],
				label: "Turnovers",
				formula: "turnovers",
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.turnovers || 0;
				},
			},
			{
				keys: ["punts", "puntingYardsGrossAverage"],
				label: "Punts (Avg)",
				formula: "punts (puntingYardsGrossAverage)",
				value: (matchStats: ISquadMatchStats) => {
					const {punts = 0, puntingYardsGrossAverage = 0} = matchStats.stats;

					return `${punts}(${toFixed(puntingYardsGrossAverage)})`;
				},
			},

			{
				keys: ["sacks"],
				label: "Sacks Allowed",
				formula: "sacks",
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.sacks || 0;
				},
			},
			{
				keys: ["drives"],
				label: "Total Poss.",
				formula: "drives",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.drives || 0;
				},
			},
			{
				keys: ["driveInsideTwentySucceeded", "driveInsideTwentyAttempted"],
				label: "Red Zone",
				formula: "driveInsideTwentySucceeded-driveInsideTwentyAttempted",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					const {driveInsideTwentySucceeded = 0, driveInsideTwentyAttempted = 0} =
						matchStats.stats;

					return `${driveInsideTwentySucceeded}-${driveInsideTwentyAttempted}`;
				},
			},
			{
				keys: ["touchdowns"],
				label: "TDs",
				formula: "touchdowns",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.touchdowns || 0;
				},
			},
			{
				keys: ["fieldGoalsSucceeded"],
				label: "FGs",
				formula: "fieldGoalsSucceeded",
				fromSeason: 2023,
				value: (matchStats: ISquadMatchStats) => {
					return matchStats.stats.fieldGoalsSucceeded || 0;
				},
			},
			{
				keys: ["timeOfPossessionSeconds"],
				label: "Time of Poss.",
				formula: "timeOfPossessionSeconds",
				value: (matchStats: ISquadMatchStats) => {
					return toTimeFormat(matchStats.stats.timeOfPossessionSeconds);
				},
			},
		].filter((stat) => {
			return !stat.fromSeason || this.season >= stat.fromSeason;
		});
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
