import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {SettingPropEnum, ClockType} from "data/enums";

export interface ISetting {
	type: SettingPropEnum;
	value: string;
}

export interface ISettingsStore {
	get settings(): ISetting[];

	fetch(): Promise<void>;

	getByProp: (props: SettingPropEnum) => ISetting | undefined;
	get clockType(): ClockType;
}

@injectable()
export class SettingsStore implements ISettingsStore {
	@observable private _settings: ISetting[] = [];

	get settings() {
		return this._settings;
	}

	get clockType(): ClockType {
		const setting = this.getByProp(SettingPropEnum.CLOCK_TYPE);
		return setting?.value as ClockType;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action
	async fetch() {
		const {data} = await this._jsonProvider.settings();

		runInAction(() => {
			this._settings = Array.isArray(data) ? data : [];
		});
	}

	getByProp = (prop: SettingPropEnum) => {
		return this._settings.find((it) => it.type === prop);
	};
}
