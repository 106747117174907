import {ISquadMatchStats} from "data/types/matchStats";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const STORAGE_KEY = process.env.REACT_APP_STORAGE || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const PUB_NUB_ENV = process.env.REACT_APP_PUB_NUB_ENV || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SQUAD_IMAGE_URL = process.env.REACT_APP_SQUAD_IMAGE_URL || "/squads/";

export const MUI_FOCUS_CLASS = ".Mui-focused";
export const DIVIDER_CLASS = "divider";
export const EMAIL_PATTERN = "[A-za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";

export const LIVE_CHANGE_CLASS = "changed";

// Oct 8th - 8:20pm EST
export const LABEL_DATE_FORMAT = "LLL d - h:mma ZZZZ";

export const SEASON = 2024;
export const OLD_STATS_SEASON = 2023;
export const GAME_FIRST_SEASON = 2004;
export const EXCLUDE_SEASONS: number[] = [2020];

export const DEFAULT_SQUAD: ISquadMatchStats = {
	squadId: 0,
	roundId: 0,
	season: 0,
	tournamentId: 0,
	isHome: 1,
	opponentSquadId: 0,
	stats: {
		firstDowns: 0,
		firstDownsRushing: 0,
		firstDownsPassing: 0,
		firstDownsPenalties: 0,
		secondDownsConversions: 0,
		secondDownsAttempted: 0,
		thirdDownsAttempted: 0,
		thirdDownsConversions: 0,
		driveInsideTwentySucceeded: 0,
		driveInsideTwentyAttempted: 0,
		driveInsideTwentySucceededPercentage: 0,
		plays: 0,
		playYardsAverage: 0,
		offenseYards: 0,
		returnsYards: 0,
		rushingYards: 0,
		passesSucceededYards: 0,
		passesAttempted: 0,
		passesSucceeded: 0,
		interceptions: 0,
		touchdowns: 0,
		passesAttemptedYardsAverage: 0,
		passesSucceededPercentage: 0,
		losses: 0,
		lossesYards: 0,
		punts: 0,
		puntingYardsGrossAverage: 0,
		puntingYardsNetAverage: 0,
		puntsReturns: 0,
		puntingReturnsYards: 0,
		kickoffsReturns: 0,
		kickoffsReturnsYards: 0,
		fieldGoalsMissedReturns: 0,
		fieldGoalsMissedReturnsYards: 0,
		rushesTwentyPlusYards: 0,
		passesSucceededThirtyPlusYards: 0,
		touchdownsRushing: 0,
		touchdownsPasses: 0,
		penaltiesChargedDefense: 0,
		penaltiesChargedOffense: 0,
		penaltiesForLossYards: 0,
		fumbles: 0,
		fumblesLost: 0,
		extraPointsSucceeded: 0,
		extraPointsAttempted: 0,
		twoPointPassSucceeded: 0,
		twoPointRushSucceeded: 0,
		twoPointDefensiveConversionsSucceeded: 0,
		twoPointPassAttempted: 0,
		twoPointRushAttempted: 0,
		twoPointDefensiveConversionsAttempted: 0,
		fieldGoalsSucceeded: 0,
		fieldGoalsAttempted: 0,
		safeties: 0,
		timeOfPossessionSeconds: 0,
		turnovers: 0,
		sacks: 0,
		drives: 0,
		secondDown: 0,
		thirdDown: 0,
		passesAttemptedCompletedPercentage: 0,
		touchdownsOther: 0,
		penaltiesYards: 0,
		twoPointsConversionsMade: 0,
		twoPointsConversionsAttempted: 0,
		conversionsMade: 0,
		conversionsAttempted: 0,
		rushes: 0,
		rushingYardsAverage: 0,
		passesIntercepted: 0,
	},
};

// Todo: Remove once it will opened for prod
export const HIDE_DRIVE_CHART = process.env.REACT_APP_HIDE_DRIVE_CHART === "true";
// Todo: Remove once it will opened for prod
export const HIDE_TOTAL_ON_DEFENCE = process.env.REACT_APP_HIDE_TOTAL_ON_DEFENCE === "true";
