import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IUser} from "data/types/entities";

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IGameBookApiProvider {
	generate: (tournamentId: number) => Promise<AxiosResponse<TLoginResponse>>;
}

@injectable()
export class GameBookApiProvider implements IGameBookApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	generate = (tournamentId: number) =>
		this._http.get<TLoginResponse>("pdf/generate", {tournamentId});
}
