import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {find} from "lodash";
import {SquadUtils} from "data/utils/squad";
import {Empty} from "data/types/generics";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import {ISquadMatchStats, ISquadStats} from "data/types/matchStats";
import {DEFAULT_SQUAD} from "data/constants";
import type {IHighlightStore} from "data/stores/highlight/highlight.store";
import {ISquad} from "data/types/entities";

interface ITeams {
	firstSquadAbbr: ReturnType<typeof SquadUtils.getAbbreviation>;
	secondSquadAbbr: ReturnType<typeof SquadUtils.getAbbreviation>;
	firstSquadStats?: ISquadMatchStats;
	secondSquadStats?: ISquadMatchStats;
	firstSquad?: ISquad;
	secondSquad?: ISquad;
}

export interface ITeamsController extends ViewController {
	get firstSquadColor(): string;
	get secondSquadColor(): string;

	get firstSquadID(): Empty<number>;
	get secondSquadID(): Empty<number>;

	get getSquadsHeader(): {firstSquadName: string; secondSquadName: string};
	get getTeams(): ITeams;
	get firstSquadStats(): Empty<ISquadStats>;
	get secondSquadStats(): Empty<ISquadStats>;
	get isRoundWithAllStats(): boolean;
}

@injectable()
export class TeamsController implements ITeamsController {
	constructor(
		@inject(Bindings.MatchStatsStore) private _matchStatsStore: IMatchStatsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.HighlightStore) private _highlightStore: IHighlightStore
	) {
		makeAutoObservable(this);
	}

	public get firstSquadColor() {
		return this.getTeams.firstSquad?.color || "#000";
	}

	public get secondSquadColor() {
		return this.getTeams.secondSquad?.color || "#000";
	}

	public get firstSquadID() {
		return this.getTeams.firstSquad?.id;
	}

	public get secondSquadID() {
		return this.getTeams.secondSquad?.id;
	}

	public get firstSquadStats() {
		const teams = this.getTeams;
		return {
			...DEFAULT_SQUAD?.stats,
			...teams.firstSquadStats?.stats,
		};
	}

	public get secondSquadStats() {
		const teams = this.getTeams;
		return {
			...DEFAULT_SQUAD?.stats,
			...teams.secondSquadStats?.stats,
		};
	}

	public get getSquadsHeader() {
		const teams = this.getTeams;
		return {
			firstSquadName: teams.secondSquadAbbr,
			secondSquadName: teams.firstSquadAbbr,
		};
	}

	get getTeams(): ITeams {
		const tournament = this._roundsStore.selectedTournament;
		const firstSquadID = tournament?.awaySquad.id ?? 0;
		const secondSquadID = tournament?.homeSquad.id ?? 0;
		const firstSquad = this._squadsStore.getSquadById(firstSquadID);
		const secondSquad = this._squadsStore.getSquadById(secondSquadID);
		const statsObject = this._matchStatsStore.squads;

		const firstSquadStats =
			find(statsObject, (squadStats) => squadStats.squadId === firstSquadID) || DEFAULT_SQUAD;
		const secondSquadStats =
			find(statsObject, (squadStats) => squadStats.squadId === secondSquadID) ||
			DEFAULT_SQUAD;

		if (this._matchStatsStore.isLoading) {
			return {
				firstSquadAbbr: SquadUtils.getAbbreviation(secondSquad),
				secondSquadAbbr: SquadUtils.getAbbreviation(firstSquad),
				firstSquadStats: undefined,
				secondSquadStats: undefined,
				secondSquad,
				firstSquad,
			};
		}

		return {
			firstSquadAbbr: SquadUtils.getAbbreviation(secondSquad),
			secondSquadAbbr: SquadUtils.getAbbreviation(firstSquad),
			firstSquadStats,
			secondSquadStats,
			secondSquad,
			firstSquad,
		};
	}

	get isRoundWithAllStats(): boolean {
		return !this._roundsStore.isRoundWithPrevStats;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
