import React from "react";
import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IFormValidator} from "data/utils/validators/FormValidator";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {PasswordValidator} from "data/utils/validators/PasswordValidator";
import {ValidationScheme} from "data/types/validators";
import {IRegisterPayload} from "data/types/entities";
import {AxiosError} from "axios";

interface IRegisterForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	firstName: HTMLInputElement;
	lastName: HTMLInputElement;
	organization: HTMLInputElement;
}

export interface IRegistrationController extends ViewController {
	togglePasswordVisibility: () => void;
	toggleConfirmPasswordVisibility: () => void;
	handleFormSubmit: (event: React.SyntheticEvent<IRegisterForm>) => void;
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
	handleTermsEndReach: () => void;

	get errors(): Record<string, string | undefined>;

	get error(): string | undefined;

	get isLoading(): boolean;

	get isButtonDisabled(): boolean;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;
}

@injectable()
export class RegistrationController implements IRegistrationController {
	@observable private _isEndReach: boolean = false;
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
	};

	constructor(
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
		this._formValidator.enterScheme(this._validationScheme);
	}

	@observable _error?: string = undefined;

	get error() {
		return this._error;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isButtonDisabled(): boolean {
		return this.isLoading || !this._isEndReach;
	}

	get errors(): Record<string, string | undefined> {
		return this._formValidator.formErrors;
	}

	@action
	public togglePasswordVisibility = () => {
		this._isPasswordVisible = !this._isPasswordVisible;
	};

	@action
	public toggleConfirmPasswordVisibility = () => {
		this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
	};

	@action
	public handleTermsEndReach = () => {
		this._isEndReach = true;
	};

	@action
	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
		const name = event.target.name;

		if (!name) {
			return;
		}

		this._formValidator.clearError(name);
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<IRegisterForm>) => {
		event.preventDefault();
		const {email, password, confirmPassword, firstName, lastName, organization} =
			event.currentTarget;
		const isFormValid = this._formValidator.validate(event.currentTarget);

		if (!isFormValid) {
			return;
		}

		if (password.value !== confirmPassword.value) {
			this._formValidator.setError("confirmPassword", "true");
			return;
		}

		const payload: IRegisterPayload = {
			email: email.value,
			firstName: firstName.value,
			lastName: lastName.value,
			organization: organization.value,
			password: password.value,
			termsOptin: true,
		};

		this._isLoading = true;
		this._userStore
			.register(payload)
			.catch(this.onRegisterError.bind(this))
			.finally(this.onRegisterFinally.bind(this));
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	protected onRegisterError(error: AxiosError<{errors: {message: string}[]}>) {
		this._error = error.response?.data?.errors[0]?.message || error.message;
		this._isLoading = false;
	}

	protected onRegisterFinally(): void {
		this._isLoading = false;
	}
}
