import {makeAutoObservable, observable} from "mobx";
import {INavigateParams, ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {ITournament, ITournamentSquad} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {NavigateFunction} from "react-router-dom";
import {createConnextraScriptTag, ConnextraType} from "data/utils/connextra";

export interface IFixtureInfoController extends ViewController<INavigateParams> {
	get tournamentId(): Empty<number>;

	get homeSquad(): Empty<ITournamentSquad>;

	get awaySquad(): Empty<ITournamentSquad>;

	navigateBack: () => void;
	onGameBookClick: () => void;
}

@injectable()
export class FixtureInfoController implements IFixtureInfoController {
	@observable protected _navigate: Empty<NavigateFunction>;
	constructor(@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore) {
		makeAutoObservable(this);
	}

	get awaySquad(): Empty<ITournamentSquad> {
		return this.tournament?.awaySquad;
	}

	get homeSquad(): Empty<ITournamentSquad> {
		return this.tournament?.homeSquad;
	}

	get tournamentId(): Empty<number> {
		return this.tournament?.id;
	}

	protected get tournament(): Empty<ITournament> {
		return this._roundsStore.selectedTournament;
	}

	dispose(): void {
		return;
	}

	init(param: INavigateParams): void {
		this._navigate = param.navigate;
	}

	public navigateBack = () => {
		this._roundsStore.clearSelectedTournament();
		this._navigate?.("/fixture");
	};

	public onGameBookClick = () => {
		createConnextraScriptTag(ConnextraType.FIXTUREPAGE_GAMEBOOK);
	};
}
