import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable} from "inversify";
import {AlertColor} from "@mui/material/Alert/Alert";
import type {IMatchStats} from "data/types/matchStats";
import {FixtureTabs} from "data/enums";
import {find} from "lodash";
import {objectDiff} from "data/utils/helpers/diff";

export interface IGameMessage {
	message: string;
	color: AlertColor;
}

type StatsByTabs = Record<
	FixtureTabs,
	{
		diffs: {
			diff: ReturnType<typeof objectDiff> | null;
			id: number;
			timeout: ReturnType<typeof setTimeout>;
		}[];
		stats: IMatchStats | null;
		isDisabled: boolean;
	}
>;

export interface IHighlightStore {
	get statsByTabs(): StatsByTabs;

	setLatestMatchStats(matchStats: IMatchStats): void;

	calculateDiffForTab(tab?: FixtureTabs): void;
	disableDiffForTab(tab?: FixtureTabs): void;
	enableDiffForTab(tab?: FixtureTabs): void;

	isHighlight(key: string): boolean;

	resetDiffs(): void;
	makeDiff(current: unknown, prev: unknown): string[];

	reset(): void;
}

@injectable()
export class HighlightStore implements IHighlightStore {
	private _diffTTL = 4000;
	/**
	 * Used to store actual stats
	 */
	@observable private _latestMatchStats: IMatchStats | null = null;

	/**
	 * Used to store actual stats of the last event when the tab has been opened.
	 */
	private _statsByTabs: StatsByTabs = {
		[FixtureTabs.GameCast]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
		[FixtureTabs.Team]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
		[FixtureTabs.Offence]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
		[FixtureTabs.KickReturns]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
		[FixtureTabs.PlayList]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
		[FixtureTabs.Scoring]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
		[FixtureTabs.Defence]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
		[FixtureTabs.DriveChart]: {
			diffs: [],
			stats: null,
			isDisabled: false,
		},
	};
	@observable private _currentTab: FixtureTabs | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	get statsByTabs() {
		return this._statsByTabs;
	}

	setLatestMatchStats(matchStats: IMatchStats) {
		this._latestMatchStats = matchStats;
		if (this._currentTab && !this._statsByTabs[this._currentTab].isDisabled) {
			this.calculateDiff(this._currentTab);
			this._statsByTabs[this._currentTab].stats = matchStats;
		}
	}

	calculateDiffForTab(tab?: FixtureTabs) {
		this._currentTab = tab ?? null;
		if (tab && this._latestMatchStats && !this._statsByTabs[tab].isDisabled) {
			this.calculateDiff(tab);
			this._statsByTabs[tab].stats = this._latestMatchStats;
		}
	}

	disableDiffForTab(tab?: FixtureTabs) {
		if (tab) {
			this._statsByTabs[tab].isDisabled = true;
		}
	}

	enableDiffForTab(tab?: FixtureTabs) {
		if (tab) {
			this._statsByTabs[tab].isDisabled = false;
		}
	}

	resetDiffs() {
		if (find(this._statsByTabs, (stat) => !!stat.diffs?.length)) {
			Object.keys(this._statsByTabs).forEach((key) => {
				runInAction(() => {
					this._statsByTabs[key as FixtureTabs].diffs = [];
				});
			});
		}
	}

	reset() {
		Object.keys(this._statsByTabs).forEach((key) => {
			this._statsByTabs[key as FixtureTabs] = {
				diffs: [],
				stats: null,
				isDisabled: false,
			};
		});

		this._latestMatchStats = null;
	}

	makeDiff(current: unknown, prev: unknown): string[] {
		return objectDiff(current, prev);
	}

	/**
	 * Finds if the value has been changed by key
	 * @example isHighlight('players.[1].stats.rushes')
	 * @param key
	 */
	isHighlight(key: string) {
		// console.log(key);
		if (this._currentTab) {
			const diffs = this._statsByTabs[this._currentTab].diffs;
			return diffs.some((diff) => Boolean(diff.diff?.includes(key)));
		}
		return false;
	}

	private calculateDiff(tab: FixtureTabs) {
		const oldStats = this._statsByTabs[tab].stats;
		if (!!oldStats && this._latestMatchStats) {
			const newDiff = objectDiff(oldStats, this._latestMatchStats, {
				iterateArrayKey: "id",
			});

			const diffId = new Date().getTime() + this._diffTTL;

			if (newDiff.length) {
				this._statsByTabs[tab].diffs = [
					...this._statsByTabs[tab].diffs,
					{
						diff: newDiff,
						id: diffId,
						timeout: setTimeout(() => {
							runInAction(() => {
								this._statsByTabs[tab].diffs = this._statsByTabs[tab].diffs.filter(
									(it) => it.id !== diffId
								);
							});
						}, this._diffTTL),
					},
				];
			}
		}
	}
}
