import {ITournament} from "data/types/entities";
import {Empty} from "data/types/generics";
import {IStatPlayer} from "data/types/matchStats";

export abstract class PlayerUtils {
	static isPassing = (player: IStatPlayer) => {
		return !!player.stats.passesAttempted;
	};

	static isReceiving = (player: IStatPlayer) => {
		return !!player.stats.passesTargetedAt;
	};

	static isRushing = (player: IStatPlayer) => {
		return !!player.stats.rushes;
	};

	static isKickOff = (player: IStatPlayer) => {
		return !!player.stats.kickoffs;
	};

	static isPunts = (player: IStatPlayer) => {
		return !!player.stats.punts;
	};

	static isFieldGoals = (player: IStatPlayer) => {
		return !!player.stats.fieldGoalsAttempted || !!player.stats.extraPointsAttempted;
	};

	static isKickOffReturns = (player: IStatPlayer) => {
		return !!player.stats.kickoffsReturns || !!player.stats.kickoffsReturnsYards;
	};

	static isPuntsReturns = (player: IStatPlayer) => {
		return !!player.stats.puntsReturns || !!player.stats.puntingReturnsYards;
	};

	static isFieldGoalsMissedReturns = (player: IStatPlayer) => {
		return !!player.stats.fieldGoalsMissedReturns;
	};

	// tacklesSolo
	// tacklesSpecialTeams
	// tacklesForLoss
	// tacklesSpecialTeams
	// interceptionsReturnsYards
	// fumblesRecoveries
	// fumblesReturnsYards
	// passesDefended
	// 	= puntsBlocked + fieldGoalsBlocked + extraPointBlocked

	static isDefensive = (player: IStatPlayer) => {
		const stats: (keyof typeof player.stats)[] = [
			"interceptions",
			"tackles",
			"fumblesForced",
			"sacks",
			"tacklesSolo",
			"tacklesForLoss",
			"tacklesSpecialTeam",
			"interceptionsReturnsYards",
			"fumblesRecoveries",
			"fumblesReturnsYards",
			"passesDefended",
			"puntsBlocked",
			"fieldGoalsBlocked",
			"extraPointBlocked",
		];

		return stats.some((stat) => Boolean(player.stats[stat]));
	};

	static isTeamLosses = (player: IStatPlayer) => {
		const stats: (keyof typeof player.stats)[] = [
			"losses",
			"lossesYards",
			"passesSacked",
			"lossesOther",
			"fumbles",
			"fumblesLost",
		];

		return stats.some((stat) => Boolean(player.stats[stat]));
	};

	static isFirstSquad = (player: {squadId?: number}, tournament: Empty<ITournament>) => {
		return player?.squadId === tournament?.awaySquad.id;
	};

	static isSecondSquad = (player: {squadId?: number}, tournament: Empty<ITournament>) => {
		return player?.squadId === tournament?.homeSquad.id;
	};

	static buildPLayerName(player: IStatPlayer) {
		if (player.firstName && player.lastName) {
			return `${player.firstName[0]}. ${player.lastName}`;
		}

		return player.lastName || player.firstName;
	}

	/**
	 * Builds props for PlayerNameCell
	 * @see PlayerNameCell
	 * @param player
	 */
	static buildPlayerNameCell = (player: IStatPlayer) => {
		return {
			position: player.position,
			name: PlayerUtils.buildPLayerName(player),
			rank: player.number,
		};
	};
}
