import {ThemeOptions} from "@mui/material/styles/createTheme";
import {baseFont} from "assets/theme/typography.theme";
import {colorsTheme} from "assets/theme/colors.theme";
import {LIVE_CHANGE_CLASS} from "data/constants";

export const tableTheme: ThemeOptions = {
	components: {
		MuiTableContainer: {
			styleOverrides: {
				root: {
					border: "1px solid #C2C2C2",
					borderRadius: 4,
					background: "#FFFFFF",
					opacity: 0,
					animation: "fadeIn 0.5s ease forwards",
				},
			},
		},
		MuiTable: {
			defaultProps: {
				size: "small",
			},
			styleOverrides: {
				root: {
					borderTop: "4px solid var(--squadColor)",
				},
			},
		},

		MuiTableHead: {
			styleOverrides: {
				root: {
					borderBottom: "1px solid rgba(18, 18, 18, 0.15)",
					textTransform: "uppercase",
				},
			},
			variants: [
				{
					props: {className: "hidden"},
					style: {
						visibility: "collapse",
						border: "none",
					},
				},
			],
		},
		MuiTableRow: {
			defaultProps: {
				className: "even",
			},
			styleOverrides: {
				root: {
					transition: "all 0.3s ease",
					"&:hover": {
						background: colorsTheme.color.components.table.hoverRowColor,
					},
				},
			},
			variants: [
				{
					props: {className: "transparent"},
					style: {
						background: "transparent",
					},
				},
				{
					props: {className: "secondFont"},
					style: {
						td: {
							fontFamily: baseFont,
						},
					},
				},
				{
					props: {className: "even"},
					style: {
						"&:not(.collapse):nth-of-type(2n)": {
							background: colorsTheme.color.components.table.secondaryRowBackground,
							"&:hover": {
								background: colorsTheme.color.components.table.hoverRowColor,
							},
						},
					},
				},
				{
					props: {className: "odd"},
					style: {
						"&:not(.collapse):nth-of-type(2n + 1)": {
							background: colorsTheme.color.components.table.secondaryRowBackground,
							"&:hover": {
								background: colorsTheme.color.components.table.hoverRowColor,
							},
						},
					},
				},
				{
					props: {className: "darken"},
					style: {
						background: colorsTheme.color.components.table.secondaryRowBackground,
						"&:hover": {
							background: colorsTheme.color.components.table.hoverRowColor,
						},
					},
				},
				{
					props: {className: LIVE_CHANGE_CLASS},
					style: {
						background: "rgba(255, 152, 0, 0.15)",
						border: "none",
						transition: "all 0.5s ease-in",
					},
				},
			],
		},
		MuiTableCell: {
			defaultProps: {
				align: "right",
			},
			styleOverrides: {
				root: {
					border: "none",
					padding: "6px 10px",
					transition: "all 1s ease-in",
					"&.divider": {
						borderLeft: "2px solid #C2C2C2",
					},
				},
				head: {
					fontFamily: baseFont,
					fontSize: "16px",
					fontWeight: 600,
					color: "#3A3A3C",
					padding: "2px 10px",
					whiteSpace: "nowrap",
				},
				head2: {
					background: colorsTheme.color.components.table.secondaryRowBackground,
					fontFamily: baseFont,
					fontSize: "16px",
					fontWeight: 600,
					color: "#3A3A3C",
					padding: "2px 10px",
					whiteSpace: "nowrap",
				},
				firstColor: {
					borderLeft: "2px solid var(--firstSquadColor)",
				},
				secondColor: {
					borderLeft: "2px solid var(--secondSquadColor)",
				},
				bold: {
					fontWeight: "bold",
				},
				singleTableDark: {
					fontWeight: 600,
					background: colorsTheme.color.components.table.secondaryRowBackground,
					fontFamily: baseFont,
					fontSize: "16px",
				},
				singleTableLight: {
					fontWeight: 600,
					fontFamily: baseFont,
					fontSize: "16px",
				},
				singleTableValueDark: {
					fontWeight: "bold",
					background: colorsTheme.color.components.table.secondaryRowBackground,
				},
				singleTableValueLight: {
					fontWeight: "bold",
				},
			},
			variants: [
				{
					props: {className: LIVE_CHANGE_CLASS},
					style: {
						background: "rgba(255, 152, 0, 0.15)",
						border: "none",
						transition: "all 0.5s ease-in",
					},
				},
			],
		},
	},
};
