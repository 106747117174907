import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {RoundStatus, SeasonFilter, TournamentStatus} from "data/enums";
import {last, findLast} from "lodash";
import {ITournament} from "data/types/entities";
import type {Empty} from "data/types/generics";
import {OLD_STATS_SEASON, SEASON, GAME_FIRST_SEASON, EXCLUDE_SEASONS} from "data/constants";

export interface IRound {
	id: number;
	status: RoundStatus;
	startDate: string;
	endDate: string;
	season: number;
	tournaments: ITournament[];
	name: string;
}

export interface IRoundsStore {
	get getIsLoading(): boolean;

	get list(): IRound[];

	get scheduleRounds(): IRound[];

	get activeRound(): Empty<IRound>;

	get years(): number[];

	get weeks(): number[];

	get yearRounds(): IRound[];

	get selectedYear(): number;

	set selectedYear(value: number);

	get selectedWeek(): number;

	set selectedWeek(value: number);

	get selectedSeasonState(): SeasonFilter | null;

	set selectedSeasonState(value: SeasonFilter | null);

	get selectedRound(): Empty<IRound>;

	get selectedRoundTournaments(): ITournament[];

	get selectedTournament(): Empty<ITournament>;

	set selectedTournament(value: Empty<ITournament>);

	get nextScheduledTournament(): Empty<ITournament>;

	get nextPlayingTournament(): Empty<ITournament>;

	get lastCompletedTournament(): Empty<ITournament>;

	get isRoundWithPrevStats(): boolean;

	fetchRounds(): Promise<void>;

	getTournamentById(tournamentId: Empty<number>): Empty<ITournament>;

	clearSelectedTournament(): void;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _selectedTournament: Empty<ITournament>;

	get selectedTournament(): Empty<ITournament> {
		return this._selectedTournament;
	}

	set selectedTournament(value: Empty<ITournament>) {
		this._selectedTournament = value;
	}

	@observable private _list: IRound[] = [];

	get list() {
		return this._list;
	}

	get scheduleRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Complete);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	@observable private _selectedWeek: number = 0;

	get selectedWeek(): number {
		return this._selectedWeek || this.nearestWeek;
	}

	set selectedWeek(value: number) {
		this._selectedWeek = value;
	}

	@observable private _selectedSeasonState: SeasonFilter | null = null;

	get selectedSeasonState(): SeasonFilter | null {
		return this._selectedSeasonState;
	}

	set selectedSeasonState(value: SeasonFilter | null) {
		this._selectedSeasonState = value;
	}

	@observable private _selectedYear: number = new Date().getFullYear();

	get selectedYear(): number {
		return this._selectedYear;
	}

	set selectedYear(value: number) {
		this._selectedYear = value;
	}

	get weeks(): number[] {
		return this.yearRounds.map((round) => round.id);
	}

	get years(): number[] {
		const years = [];
		let startYear = GAME_FIRST_SEASON;

		while (startYear <= SEASON) {
			if (!EXCLUDE_SEASONS.includes(startYear)) {
				years.push(startYear);
			}
			startYear++;
		}
		return years;
	}

	get selectedRound() {
		return this.yearRounds.find((round) => round.id === this.selectedWeek);
	}

	get selectedRoundTournaments() {
		const tournaments = this.selectedRound?.tournaments || [];
		return tournaments.filter((tournament) => tournament.type === this.selectedSeasonState);
	}

	get allMatches() {
		return this._list.flatMap((it) => it.tournaments);
	}

	get nextScheduledTournament() {
		return this.allMatches.find((it) => [TournamentStatus.Scheduled].includes(it.status));
	}

	get nextPlayingTournament() {
		return this.allMatches.find((it) => [TournamentStatus.Playing].includes(it.status));
	}

	get lastCompletedTournament() {
		return findLast(this.allMatches, (it) => [TournamentStatus.Complete].includes(it.status));
	}

	get isRoundWithPrevStats(): boolean {
		return this.selectedYear < OLD_STATS_SEASON;
	}

	protected get rounds(): IRound[] {
		return this.list;
	}

	get yearRounds(): IRound[] {
		return this.rounds.filter((round) => {
			return round.tournaments.some((match) => match.type === this.selectedSeasonState);
		});
	}

	protected get nearestWeek(): number {
		const activeRound = this.yearRounds.find((round) => round.status !== RoundStatus.Complete);
		if (activeRound) {
			return activeRound.id;
		}

		return last(this.yearRounds)?.id || 0;
	}

	get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Playing);
	}

	@action
	async fetchRounds() {
		const {data} = await this._jsonProvider.roundsByYear(this.selectedYear);

		runInAction(() => {
			this._list = Array.isArray(data) ? data : [];
		});

		// this._list = Array.isArray(data)
		// 	? data.map((it) => {
		// 		return {
		// 			...it,
		// 			tournaments: it.tournaments.map((match) => {
		// 				if( match.id === 1680 ) {
		// 					return {
		// 						...match,
		// 						status: TournamentStatus.Playing,
		// 						isHidden: false,
		// 						isPreMatch: true,
		// 						clock: "00:15:00",
		// 					};
		// 				}
		// 				return {
		// 					...match,
		// 				};
		// 			}),
		// 		};
		// 	})
		// 	: [];
	}

	public getTournamentById(tournamentId: Empty<number>) {
		const tournamentFromRound = this.selectedRound?.tournaments.find(
			(e) => e.id === tournamentId
		);
		if (tournamentFromRound) {
			return tournamentFromRound;
		}

		return this._list.flatMap((e) => e.tournaments).find((e) => e.id === tournamentId);
	}

	public clearSelectedTournament() {
		this.selectedTournament = undefined;
	}
}
