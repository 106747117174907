export enum ConnextraType {
	FIXTUREHUB_STATS = "cflconnect_fixturehub_stats",
	FIXTUREHUB_GAMEBOOK = "cflconnect_fixturehub_gamebook",
	FIXTUREPAGE_GAMEBOOK = "cflconnect_fixturepage_gamebook",

	FIXTUREPAGE_DEFENCE = "cflconnect_fixturepage_defence",
	FIXTUREPAGE_DRIVECHART = "cflconnect_fixturepage_drivechart",
	FIXTUREPAGE_GAMECAST = "cflconnect_fixturepage_gamecast",
	FIXTUREPAGE_KICKS_RETURNS = "cflconnect_fixturepage_kicks_returns",
	FIXTUREPAGE_OFFENCE = "cflconnect_fixturepage_offence",
	FIXTUREPAGE_PLAYLIST = "cflconnect_fixturepage_playlist",
	FIXTUREPAGE_SCORING = "cflconnect_fixturepage_scoring",
	FIXTUREPAGE_TEAM = "cflconnect_fixturepage_team",
	PLAYLIST_EXPANSION = "cflconnect_playlist_expansion",
}

const CONNEXTRA_URL = `https://zz.connextra.com/dcs/tagController/tag/8ce716f59752/`;

export const createConnextraScriptTag = (type: ConnextraType, accountId: number = 0) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";

	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (accountId) {
		url.searchParams.append("AccountID", `${accountId}`);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
