import React, {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {HIDE_DRIVE_CHART} from "data/constants";

const Home = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const Fixtures = lazy(retryFailLoad(() => import("views/pages/fixture/fixture.page")));
const FixtureHubPage = lazy(
	retryFailLoad(() => import("views/pages/fixture_hub/fixture_hub.page"))
);
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const GameCastPage = lazy(
	retryFailLoad(() => import("views/pages/fixture/game_cast/game_cast.page"))
);
const PlayListPage = lazy(
	retryFailLoad(() => import("views/pages/fixture/play_list/play_list.page"))
);
const TeamPage = lazy(retryFailLoad(() => import("views/pages/fixture/team/team.page")));
const DefencePage = lazy(retryFailLoad(() => import("views/pages/fixture/defence/defence.page")));
const KickReturnsPage = lazy(
	retryFailLoad(() => import("views/pages/fixture/kick_returns/kick_returns.page"))
);
const OffencePage = lazy(retryFailLoad(() => import("views/pages/fixture/offence/offence.page")));
const ScoringPage = lazy(retryFailLoad(() => import("views/pages/fixture/scoring/scoring.page")));
const DriveChartPage = lazy(
	retryFailLoad(() => import("views/pages/fixture/drive_chart/drive_chart.page"))
);
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

const Login = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const Registration = lazy(
	retryFailLoad(() => import("views/pages/registration/registration.page"))
);
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<NotAuthOnlyRoute />}>
			<Route index element={<Home />} />
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Registration />} />
			<Route path="/reset-password" element={<ResetPassword />} />
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/fixture" element={<FixtureHubPage />} />
			<Route path="/fixture/:id" element={<Fixtures />}>
				<Route index={true} element={<Navigate to={"game_cast"} />} />
				<Route path="game_cast" element={<GameCastPage />} />
				<Route path="team" element={<TeamPage />} />
				<Route path="offence" element={<OffencePage />} />
				<Route path="kick_returns" element={<KickReturnsPage />} />
				<Route path="defence" element={<DefencePage />} />
				<Route path="play_list" element={<PlayListPage />} />
				<Route path="scoring" element={<ScoringPage />} />
				{!HIDE_DRIVE_CHART && <Route path="drive_chart" element={<DriveChartPage />} />}
				<Route path="*" element={<Navigate to={"game_cast"} />} />
			</Route>
		</Route>
		<Route path="help/*" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default RootRoutes;
