import {ThemeOptions} from "@mui/material/styles/createTheme";

export const baseFont = ["Khand", "sans-serif"].join(",");
export const secondaryFont = ["Hind", "sans-serif"].join(",");

function generateHeadingFont(
	fontSize: string,
	lineHeight: string,
	fontWeight = 500,
	letterSpacing = "",
	font?: string
) {
	return {
		...generateBaseFont(fontSize, lineHeight, fontWeight, letterSpacing),
		fontFamily: font ?? baseFont,
	};
}

function generateBaseFont(
	fontSize: string,
	lineHeight: string,
	fontWeight = 500,
	letterSpacing = ""
) {
	return {
		fontSize,
		lineHeight,
		fontWeight: fontWeight || 500,
		letterSpacing: letterSpacing || "0.005em",
		fontFamily: secondaryFont,
	};
}

export const typographyTheme: ThemeOptions = {
	typography: {
		h1: generateHeadingFont("28px", "43px"),
		h2: generateHeadingFont("32px", "49px", 700),
		h3: generateHeadingFont("28px", "43px", 500),
		h4: generateHeadingFont("24px", "37px", 500, "0.02em"),
		h5: generateHeadingFont("20px", "31px", 700, "0.02em"),
		h6: generateHeadingFont("18px", "28px", 700, "0.02em"),

		body1: generateBaseFont("16px", "16px", 400, "0.015em"),
		body2: generateBaseFont("14px", "22px", 400, "0.015em"),
		body3: generateBaseFont("12px", "19px", 400, "0.015em"),
		body1Medium: generateBaseFont("16px", "26px", 500, "0.015em"),
		body2Medium: generateBaseFont("14px", "22px", 500, "0.015em"),
		body3Medium: generateBaseFont("12px", "19px", 500, "0.015em"),

		caption: generateBaseFont("10px", "16px", 400, "110%"),
		captionMedium: generateBaseFont("10px", "16px", 500, "110%"),

		subtitle1: generateHeadingFont("16px", "24px", 600, "0.02em"),
		subtitle2: generateBaseFont("12px", "18px", 500, "0.01em"),

		headerS: generateHeadingFont("20px", "30px", 500, "0.02em"),

		allVariants: {
			fontFamily: baseFont,
		},

		button: {
			fontFamily: baseFont,
			textTransform: "uppercase",
			fontWeight: "600",
			padding: "8px 22px",
			fontSize: "16px",
		},
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					input: {
						fontFamily: secondaryFont,
					},
					legend: {
						fontFamily: secondaryFont,
					},
					label: {
						fontFamily: secondaryFont,
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontFamily: secondaryFont,
				},
			},
		},
	},
};
