import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ITournament} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IFixtureHubController extends ViewController {
	get tournaments(): ITournament[];
}

@injectable()
export class FixtureHubController implements IFixtureHubController {
	constructor(@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get tournaments(): ITournament[] {
		return this._roundsStore.selectedRoundTournaments;
	}
}
