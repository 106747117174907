import {TextField} from "@mui/material";
import styled from "@emotion/styled";
import {secondaryFont} from "assets/theme/typography.theme";

export const Input = styled(TextField)`
	.MuiInputBase-root {
		background: #ffffff;
		font-size: 16px;
		line-height: 24px;

		@media (max-width: 880px) {
			font-size: 12px;
			line-height: 24px;
		}
	}

	& input {
		padding: 4px 9px;
		font-weight: 400;
		font-family: ${secondaryFont};
		border: none;
		text-transform: uppercase;

		font-size: 16px;
		line-height: 24px;

		@media (max-width: 880px) {
			font-size: 12px;
			line-height: 24px;
		}
	}

	fieldset {
		border: none !important;
	}

	&:hover {
		* {
			transition: 0.25s;
		}

		input {
			color: #121212;
		}
	}

	& .Mui-disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	.MuiSelect-select {
		font-weight: 400;
		font-family: ${secondaryFont};
		padding: 4px 9px;
	}
`;
