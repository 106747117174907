import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {ITournament} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {TournamentStatus} from "data/enums";
import type {IGameBookApiProvider} from "data/providers/api/game_book.api.provider";
import type {IGameMessageStore} from "data/stores/game_message/game_message.store";
import {trackSentryErrors} from "data/utils";
import type {IUserStore} from "data/stores/user/user.store";

interface IParams {
	tournamentId: number;
}

export interface IGameBookButtonController extends ViewController<IParams> {
	requestPDF: () => void;
	update: (params: IParams) => void;
}

@injectable()
export class GameBookButtonController implements IGameBookButtonController {
	@observable private _tournamentId: Empty<number>;

	constructor(
		@inject(Bindings.GameBookApiProvider) private _gameBookApi: IGameBookApiProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.GameMessageStore) private _gameMessage: IGameMessageStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get tournament(): Empty<ITournament> {
		return this._roundsStore.getTournamentById(this._tournamentId);
	}

	get isDisabled(): boolean {
		return this.tournament?.status === TournamentStatus.Scheduled;
	}

	update = (params: IParams) => {
		this._tournamentId = params.tournamentId;
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._tournamentId = param.tournamentId;
	}

	requestPDF = async () => {
		if (this._tournamentId) {
			try {
				await this._gameBookApi.generate(this._tournamentId);
			} catch (error) {
				this._gameMessage.showMessage({
					color: "error",
					message: (error as Error).message || "Download failed",
				});

				trackSentryErrors(
					error,
					{
						_tournamentId: this._tournamentId,
						_user: this._userStore.user,
					},
					"requestPDF"
				);
			}
		}
	};
}
