import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IMatchStatsStore} from "data/stores/match_stats/match_stats.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import type {IHighlightStore} from "data/stores/highlight/highlight.store";
import type {IPubNubClientService} from "data/services/pub_nub/pub_nub_client.servise";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {noop} from "lodash";
import type {IClockStore, IMatchClock} from "data/stores/clock/clock.store";

interface IParams {
	tournamentId: number;
}

enum PubNubType {
	PROCESS_TYPE_DRIVES = "drives",
	PROCESS_TYPE_STATISTICS = "statistics",
}

export interface IPubNubEvent {
	tournamentId: number;
	type: PubNubType;
	gameTime?: IMatchClock;
}

export interface IFixturePageController extends ViewController {
	get isLoading(): boolean;

	setTournamentId(params: IParams): Promise<void>;

	get firstColor(): string;

	get secondColor(): string;

	get isHidden(): boolean;
}

@injectable()
export class FixturePageController implements IFixturePageController {
	@observable private _tournamentId: Empty<number>;
	private _reactionDisposer: ReturnType<typeof reaction> | null = null;

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.MatchStatsStore) private _statsStore: IMatchStatsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.HighlightStore) private _highlightStore: IHighlightStore,
		@inject(Bindings.ClockStore) private _clockStore: IClockStore,
		@inject(Bindings.PubNubClientService) private _pubNubClientService: IPubNubClientService,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return false;
	}

	get firstColor() {
		const tournament = this._roundsStore.selectedTournament;
		const awaySquad = this._squadsStore.getSquadById(tournament?.awaySquad.id);
		return awaySquad?.color || "#000";
	}

	get secondColor() {
		const tournament = this._roundsStore.selectedTournament;
		const homeSquad = this._squadsStore.getSquadById(tournament?.homeSquad.id);
		return homeSquad?.color || "#000";
	}

	get isHidden(): boolean {
		return this._roundsStore.selectedTournament?.isHidden ?? false;
	}

	dispose(): void {
		this._reactionDisposer?.();
		this._pubNubClientService.unsubscribeAll();
		this._highlightStore.reset();
		this._statsStore.clear();
		this._pubNubClientService.remove<IPubNubEvent | null>(this.fetchLive);
	}

	init() {
		return;
	}

	@action
	async setTournamentId(param: IParams): Promise<void> {
		this._tournamentId = param.tournamentId;
		this.checkActiveTournament();
		await this.loadMatchStats();
	}

	private checkActiveTournament() {
		const tournament = this._roundsStore.getTournamentById(this._tournamentId);

		if (tournament) {
			this._roundsStore.selectedTournament = tournament;
			this._roundsStore.selectedYear = new Date(tournament.date).getFullYear();
			const weekId = this._roundsStore.list.find((it) =>
				it.tournaments.find((match) => match.id === tournament.id)
			)?.id;
			if (weekId) {
				this._roundsStore.selectedWeek = weekId;
				this._roundsStore.selectedSeasonState = tournament.type;
			}
		}
	}

	private async loadMatchStats() {
		const tournamentId = this._roundsStore.selectedTournament?.id;
		if (tournamentId) {
			this._reactionDisposer = reaction(
				() => this._statsStore.allStats,
				() => {
					if (this._statsStore.allStats && this._statsStore.isLive) {
						this._highlightStore.setLatestMatchStats(this._statsStore.allStats);
					}
				}
			);

			const year = this._roundsStore.selectedYear;

			try {
				await Promise.all([
					this._statsStore.fetchStats(tournamentId, year),
					this._statsStore.fetchPlays(tournamentId, year),
				]);
			} catch (e) {
				console.error(e);
				this._modalsStore.showModal(ModalType.ERROR, {
					message: "No available stats",
				});
			}

			this._pubNubClientService.subscribe({
				channels: ["cfl_connect"],
			});

			this._pubNubClientService.listen<IPubNubEvent | null>(this.fetchLive);
		}
	}

	fetchLive = ({message}: {message: IPubNubEvent | null}) => {
		try {
			if (message?.tournamentId) {
				const {tournamentId, type} = message;
				const currentMatchID = this._roundsStore.selectedTournament?.id;
				const year = this._roundsStore.selectedYear;

				if (tournamentId === currentMatchID) {
					if (type === PubNubType.PROCESS_TYPE_STATISTICS) {
						void this._statsStore.fetchStatsLive(tournamentId, year).catch(noop);
					} else {
						if (message.gameTime && message.gameTime.clock) {
							this._clockStore.updateMatchClock(tournamentId, message.gameTime);
						}

						void this._statsStore.fetchPlaysLive(tournamentId, year).catch(noop);
					}
				}
			}
		} catch (e) {
			// Ignore errors for fetching jsons
			console.log(e);
		}
	};
}
