import Pubnub, {PubnubConfig, SubscribeParameters, UnsubscribeParameters} from "pubnub";

export interface IPubNubEvent<T = unknown> extends Pubnub.MessageEvent {
	message: T;
}

export interface IPubNubClientService {
	set uuid(value: string);
	subscribe(subscribeParameters: SubscribeParameters): void;
	unsubscribe(unSubscribeParameters: UnsubscribeParameters): void;
	unsubscribeAll(): void;
	listen<T = unknown>(callback: (messageEvent: IPubNubEvent<T>) => void): () => void;
	remove<T = unknown>(callback: (messageEvent: IPubNubEvent<T>) => void): void;
}

type IPubNubConfigConstructor = Omit<PubnubConfig, "userId" | "uuid"> & {
	userId?: string;
	uuid?: string;
};

export class PubNubClientService implements IPubNubClientService {
	private readonly config: PubnubConfig;
	private readonly client: Pubnub;
	private readonly environment: string;

	constructor(config: IPubNubConfigConstructor, env: string) {
		this.config = {
			uuid: Pubnub.generateUUID(),
			...config,
		};

		this.client = new Pubnub(this.config);
		this.environment = env;
	}

	set uuid(value: string) {
		this.client.setUUID(value);
	}

	public subscribe(subscribeParameters: SubscribeParameters) {
		this.client.subscribe(this.generateChannelNames(subscribeParameters));
	}

	public unsubscribe(unSubscribeParameters: UnsubscribeParameters) {
		this.client.unsubscribe(this.generateChannelNames(unSubscribeParameters));
	}

	public unsubscribeAll() {
		this.client.unsubscribeAll();
	}

	public listen<T = unknown>(callback: (messageEvent: IPubNubEvent<T>) => void) {
		this.client.addListener({
			message: callback,
		});

		return () => this.remove(callback);
	}

	public remove<T = unknown>(callback: (messageEvent: IPubNubEvent<T>) => void) {
		this.client.removeListener({
			message: callback,
		});
	}

	private generateChannelNames<T extends UnsubscribeParameters>(params: T): T {
		return {
			...params,
			channels: params.channels?.map((channel) => `${channel}_${this.environment}`),
		};
	}
}
