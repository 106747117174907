import React from "react";
import {observer} from "mobx-react";
import {MenuItem, ToggleButton, ToggleButtonGroup} from "@mui/material";
import styled from "@emotion/styled";
import {Input} from "views/components/сommon";
import {breakpoints} from "assets/theme/theme";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ISubHeaderFiltersController} from "views/components/sub_header_filters/sub_header_filters.controller";
import {SeasonFilter} from "data/enums";
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;

	input,
	.MuiFormControl-root,
	.MuiInputBase-root {
		width: 110px;
	}

	@media screen and (max-width: ${breakpoints.mobile}) {
		span,
		button {
			font-size: 12px;
		}

		button {
			height: 24px;
		}

		.MuiInputBase-input.MuiSelect-select {
			padding: 0 9px;
		}
	}
`;

export const SubHeaderFilters: React.FC = observer(() => {
	const navigate = useNavigate();
	const {
		years,
		list,
		selectedYear,
		selectedWeek,
		season,
		changeSeason,
		onYearChange,
		onWeekChange,
	} = useViewController<ISubHeaderFiltersController>(Bindings.SubHeaderFiltersController, {
		navigate,
	});

	return (
		<Wrapper>
			<Input select placeholder="year" value={selectedYear} onChange={onYearChange}>
				{years.map((year) => (
					<MenuItem value={year} key={year}>
						{year}
					</MenuItem>
				))}
			</Input>

			<ToggleButtonGroup exclusive value={season} onChange={changeSeason}>
				<ToggleButton value={SeasonFilter.PreSeason}>Pre</ToggleButton>
				<ToggleButton value={SeasonFilter.RegularSeason}>Reg</ToggleButton>
				<ToggleButton value={SeasonFilter.PostSeason}>Post</ToggleButton>
			</ToggleButtonGroup>

			<Input select placeholder="week" value={selectedWeek} onChange={onWeekChange}>
				{list.length ? (
					list.map((round, index) => (
						<MenuItem value={round.id} key={index}>
							{round.name}
						</MenuItem>
					))
				) : (
					<MenuItem value={0} disabled={true}>
						--
					</MenuItem>
				)}
			</Input>
		</Wrapper>
	);
});
