import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {ILoginPayload, IRegisterPayload, IResetPasswordPayload, IUser} from "data/types/entities";

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	fetchUser: () => Promise<AxiosResponse<TLoginResponse>>;
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	register: (params: IRegisterPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;

	forgotPasswordRequest: (email: string) => Promise<AxiosResponse<unknown>>;
	resetPasswordRequest: (payload: IResetPasswordPayload) => Promise<AxiosResponse<unknown>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	fetchUser = () => this._http.get<TLoginResponse>("user");

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("auth/login", params);

	register = (params: IRegisterPayload) =>
		this._http.post<TLoginResponse>("user/register", params);

	logout = () => this._http.post("auth/logout");

	forgotPasswordRequest = (email: string) => this._http.post("password_reset/request", {email});

	resetPasswordRequest = (payload: IResetPasswordPayload) =>
		this._http.post("password_reset", payload);
}
