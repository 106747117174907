import React, {Fragment, PropsWithChildren} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IHOCUserManagementController} from "views/components/hoc/hoc_user_management/hoc_user_management.controller";
import {Bindings} from "data/constants/bindings";
import {ModalUserManagement} from "views/components/modals/modal_user_management/modal_user_management.component";
import {useIdleTimer} from "react-idle-timer";

export const HOCUserManagement: React.FC<PropsWithChildren> = observer(({children}) => {
	const controller = useViewController<IHOCUserManagementController>(
		Bindings.HOCUserManagementController
	);

	/**
	 * Using the useIdleTimer hook to detect user inactivity and run callbacks accordingly
	 */
	useIdleTimer({
		onAction: () => {
			controller.tryToLogout();
		},
		onActive: () => {
			localStorage.removeItem(controller.timeStorageKey);
		},
		timeout: controller.timeLimit,
	});

	if (!controller.isUserApproved) {
		return <ModalUserManagement />;
	}

	return <Fragment>{children}</Fragment>;
});
