import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {Empty} from "data/types/generics";
import {ITournament} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {TournamentStatus} from "data/enums";
import {getIsValueEmptyOrNull} from "data/utils/helpers";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {createConnextraScriptTag, ConnextraType} from "data/utils/connextra";

interface IParams {
	tournamentId: number;
}

export interface IFixtureHubTournamentController extends ViewController<IParams> {
	get tournament(): Empty<ITournament>;

	get isDisabled(): boolean;

	get tournamentLink(): string;

	get squadScoreKeys(): string[];

	get loseTeam(): string;

	get homeSquadColor(): string;
	get awaySquadColor(): string;
	get matchHasOTScore(): boolean;
	get showStatsButton(): boolean;
	updateTournamentId: (id: number) => void;
	onStatsClick: () => void;
	onGameBookClick: () => void;
}

@injectable()
export class FixtureHubTournamentController implements IFixtureHubTournamentController {
	@observable private _tournamentId: Empty<number>;

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	private _squadScoreKeys = ["Q1Score", "Q2Score", "Q3Score", "Q4Score"];

	get squadScoreKeys(): string[] {
		if (this.matchHasOTScore) {
			return [...this._squadScoreKeys, "OTScore"];
		}
		return this._squadScoreKeys;
	}

	get tournament(): Empty<ITournament> {
		return this._roundsStore.getTournamentById(this._tournamentId);
	}

	get isDisabled(): boolean {
		return this.tournament?.status === TournamentStatus.Scheduled;
	}

	get tournamentLink(): string {
		if (!this._tournamentId || this.isDisabled) {
			return "/fixture";
		}
		return `/fixture/${this._tournamentId}/game_cast`;
	}

	get loseTeam(): string {
		if (this.tournament?.status !== TournamentStatus.Complete) {
			return "";
		}

		const homeScore = this.tournament.homeSquad.score;
		const awayScore = this.tournament.awaySquad.score;

		if (getIsValueEmptyOrNull(homeScore) || getIsValueEmptyOrNull(awayScore)) {
			return "";
		}
		return Number(homeScore) > Number(awayScore) ? "away-lose" : "home-lose";
	}

	get homeSquadColor() {
		const squad = this._squadsStore.getSquadById(this.tournament?.homeSquad.id);
		return squad?.color ?? "#222222";
	}
	get awaySquadColor() {
		const squad = this._squadsStore.getSquadById(this.tournament?.awaySquad.id);
		return squad?.color ?? "#222222";
	}

	get matchHasOTScore() {
		return Boolean(this.tournament?.homeSquad?.OTScore || this.tournament?.awaySquad?.OTScore);
	}

	get showStatsButton() {
		const startDate = this.tournament?.date ?? "";
		return new Date(startDate).getFullYear() >= 0;
	}

	updateTournamentId = (id: number) => {
		this._tournamentId = id;
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._tournamentId = param.tournamentId;
	}

	onStatsClick = () => {
		createConnextraScriptTag(ConnextraType.FIXTUREHUB_STATS);
	};

	onGameBookClick = () => {
		createConnextraScriptTag(ConnextraType.FIXTUREHUB_GAMEBOOK);
	};
}
