import {createTheme} from "@mui/material/styles";
import {ThemeOptions} from "@mui/material/styles/createTheme";
import {buttonTheme} from "assets/theme/buttons.theme";
import {tabsTheme} from "assets/theme/tabs.theme";
import {colorsTheme} from "assets/theme/colors.theme";
import {elevationTheme} from "assets/theme/elevation.theme";
import {typographyTheme} from "assets/theme/typography.theme";
import {inputsTheme} from "assets/theme/inputs.theme";
import {tableTheme} from "assets/theme/table.theme";
import {chipTheme} from "assets/theme/chip.theme";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject: ThemeOptions & typeof colorsTheme = {
	...colorsTheme,
};

type ITheme = typeof themeObject;
export const theme = createTheme(
	elevationTheme,
	buttonTheme,
	tabsTheme,
	colorsTheme,
	typographyTheme,
	inputsTheme,
	tableTheme,
	chipTheme
);

export const breakpoints = {
	mobile: "600px",
};

declare module "@mui/material/styles" {
	/**
	 *Disabled as it's allowing to avoid `any` type per MUI documentation
	 * https://mui.com/material-ui/customization/theming/#custom-variables
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}

declare module "@emotion/react" {
	/**
	 *Disabled as it's allowing to avoid `any` type per Emotion documentation
	 * https://emotion.sh/docs/typescript#define-a-theme
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}
