import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Suspense, Fragment} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {browserTracingIntegration, init, breadcrumbsIntegration} from "@sentry/react";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	DIContainer,
	services,
	providers,
	stores,
	controllers,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";
import {ThemeProvider} from "@mui/material";
import {theme} from "assets/theme/theme";
import {Header} from "views/components/header/header.component";
import {HOCMain} from "views/components/hoc/hoc_main/hoc_main.component";
import {HOCUserManagement} from "views/components/hoc/hoc_user_management/hoc_user_management.component";
import {ModalGameMessageComponent} from "views/components/modals/modal_game_message/modal_game_message.component";
import {Footer} from "views/components/footer/footer.component";

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			browserTracingIntegration(),
			breadcrumbsIntegration({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["connectlive.cfl.ca", "*.geniussports.com"],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

createRoot(root).render(
	<Fragment>
		<InjectionProvider container={DIContainer}>
			<BrowserRouter basename={BASE_URL}>
				<ThemeProvider theme={theme}>
					<HOCMain>
						<Fragment>
							<Header />
							<HOCUserManagement>
								<Suspense fallback={<PagePreloader />}>
									{SecretGateController.IS_SECRET_PASSED ? (
										<RootRoutes />
									) : (
										<SecretGateRoutes />
									)}
								</Suspense>
							</HOCUserManagement>
						</Fragment>
					</HOCMain>
					<ModalGameMessageComponent />
					<Footer />
				</ThemeProvider>
			</BrowserRouter>
		</InjectionProvider>
	</Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
