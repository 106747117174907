import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {UserStatus} from "data/enums";

export interface IModalUserManagementController extends ViewController {
	get isFirstRegister(): boolean;

	get isAwaiting(): boolean;

	get isDenied(): boolean;

	get isSuspended(): boolean;

	get userEmail(): string;
}

@injectable()
export class ModalUserManagementController implements IModalUserManagementController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	get isFirstRegister(): boolean {
		return this._userStore.isAfterRegister;
	}

	get isAwaiting(): boolean {
		return this._userStore.user?.status === UserStatus.WaitingForApprove;
	}

	get isSuspended(): boolean {
		return this._userStore.user?.status === UserStatus.Suspended;
	}

	get isDenied(): boolean {
		const status = this._userStore.user?.status || UserStatus.Denied;
		return [UserStatus.Denied, UserStatus.Disabled].includes(status);
	}

	get userEmail(): string {
		return this._userStore.user?.email || "-";
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
