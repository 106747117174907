import createTheme, {ThemeOptions} from "@mui/material/styles/createTheme";
import {deepmerge} from "@mui/utils";
import {typographyTheme} from "assets/theme/typography.theme";

export const subHeaderTabsThemeObject: ThemeOptions = {
	palette: {
		primary: {
			main: "#000",
		},
	},
	components: {
		MuiTabs: {
			defaultProps: {
				centered: false,
				indicatorColor: "primary",
				variant: "scrollable",
				scrollButtons: true,
			},
			styleOverrides: {
				root: {
					fontFamily: '"Hind", sans-serif',
					borderBottom: "none",
					display: "flex",
					alignItems: "center",
				},
				indicator: {
					display: "none",
				},
				scrollButtons: {
					width: "40px",
					height: "40px",
					borderRadius: "50%",
					background: "#5c5c5c",
					color: "#ffffff",
					margin: "0 8px",

					"&.Mui-disabled": {
						opacity: 0.5,
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontFamily: '"Hind", sans-serif',
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
					gap: "8px",
					padding: 0,
					marginRight: "8px",

					"&[hidden]": {
						minWidth: 0,
						maxWidth: 0,
						width: 0,
					},
				},
			},
		},
	},
};

export const subHeaderTabsTheme = createTheme(deepmerge(typographyTheme, subHeaderTabsThemeObject));
