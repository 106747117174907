import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IMatchStatusController} from "views/components/match_status/match_status.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {Typography} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {TypographyVariantsFull} from "data/types/material-ui";
import {TournamentStatus} from "data/enums";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: var(--statusWidth, auto);
	.caption {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 12px;
		width: 100%;
		white-space: nowrap;
	}
`;

const LiveChip = styled.div`
	background: ${({theme}) => theme.color.components.chip.live.background};
	color: ${({theme}) => theme.color.components.chip.live.color};
	padding: 0 5px;
	height: 12px;
	font-size: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	font-weight: bold;
`;

const Delayed = styled.div`
	text-transform: uppercase;
	background: #eaeaea;
	color: #ff0000;
	padding: 0px 5px;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	font-weight: bold;
`;
interface IProps {
	tournamentId: number;
	typographyVariant?: TypographyVariantsFull;
	small?: boolean;
	withDash?: boolean;
}

export const MatchStatus: React.FC<IProps> = observer(
	({tournamentId, typographyVariant = "caption", small, withDash = true}) => {
		const controller = useViewController<IMatchStatusController>(
			Bindings.MatchStatusController,
			{tournamentId, small}
		);

		useEffect(() => {
			controller.update({tournamentId, small});
		}, [controller, tournamentId, small]);

		return (
			<Wrapper>
				<Typography variant={typographyVariant} className="caption">
					<Exist when={controller.isInterrupted}>
						<Delayed>delayed</Delayed>
					</Exist>
					<Exist when={controller.isLive && !controller.isInterrupted}>
						<LiveChip className="chip">• LIVE</LiveChip>
					</Exist>
					<span>
						{
							{
								[TournamentStatus.Scheduled]: controller.date,
								[TournamentStatus.Playing]: controller.period(withDash),
								[TournamentStatus.Complete]: "FINAL",
							}[controller.status]
						}
					</span>
				</Typography>
			</Wrapper>
		);
	}
);
