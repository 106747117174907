import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import {SettingPropEnum} from "data/enums";

export interface ISettingsController extends ViewController {
	get failStateMessage(): string;
}

@injectable()
export class SettingsController implements ISettingsController {
	constructor(@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void) {
		return;
	}

	get failStateMessage(): string {
		const defaultMessage = `CFL Statistics is currently experiencing a minor technical issue. We appreciate your continued patience as we rectify the situation`;
		return (
			this._settingsStore.getByProp(SettingPropEnum.FAIL_SAFE_MESSAGE)?.value ||
			defaultMessage
		);
	}
}
