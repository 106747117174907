import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {Nullable} from "data/types/generics";

export interface IChecksumsStore {
	fetch: () => Promise<void>;
	get checksums(): IChecksums;
}

export interface IChecksums {
	players: Nullable<string>;
	rounds: Nullable<string>;
	squads: Nullable<string>;
	settings: Nullable<string>;
	clock: Nullable<string>;
}

@injectable()
export class ChecksumsStore implements IChecksumsStore {
	@observable private _checksums: IChecksums = {
		rounds: null,
		players: null,
		squads: null,
		clock: null,
		settings: null,
	};

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get checksums() {
		return this._checksums;
	}

	@action
	async fetch(): Promise<void> {
		const {data} = await this._jsonProvider.checksums();
		runInAction(() => {
			this._checksums = data;
		});
		// runInAction(() => {
		// 	this._checksums = get(window, "checksums", data);
		// 	set(window, "checksums", this._checksums);
		// });
	}
}
