import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType} from "data/enums";
import React from "react";
import {ISuccessModalPayload} from "data/types/modal";

interface IForm extends HTMLFormElement {
	email: HTMLInputElement;
}

export interface IModalForgotPasswordController extends ViewController {
	closeModal: () => void;
	submitResetPassword: (event: React.SyntheticEvent<IForm>) => void;

	get isLoading(): boolean;

	get isModalOpen(): boolean;
}

@injectable()
export class ModalForgotPasswordController implements IModalForgotPasswordController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isModalOpen(): boolean {
		return this._modalsStore.modal === ModalType.FORGOT_PASSWORD;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public closeModal = (): void => {
		this._modalsStore.hideModal();
	};

	@action
	public submitResetPassword = (event: React.SyntheticEvent<IForm>): void => {
		event.preventDefault();
		const email = event.currentTarget.email.value;

		if (!email) {
			return;
		}

		this._isLoading = true;
		this._userStore
			.forgotPasswordRequest(email)
			.then(this.onSuccess.bind(this))
			.finally(this.onFinally.bind(this));
	};

	@action
	protected onSuccess() {
		this._isLoading = false;
		this.openSuccessModal();
	}

	@action
	protected onFinally() {
		this._isLoading = false;
	}

	protected openSuccessModal() {
		this._modalsStore.showModal<ISuccessModalPayload>(ModalType.SUCCESS, {
			title: "E-mail sent",
			message:
				"A link has been sent to your email address to reset your password. If i was not received please contact us or try a different email address.",
		});
	}
}
