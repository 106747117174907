import {get} from "lodash";

interface IOptions {
	iterateArrayKey?: string;
}

export const objectDiff = (
	fistObject: unknown,
	secondObject: unknown,
	options: IOptions = {},
	currentPath: string = "",
	diffs: string[] = []
) => {
	if (typeof fistObject !== typeof secondObject) {
		return diffs.concat(currentPath);
	}

	if (typeof fistObject !== "object") {
		if (fistObject !== secondObject) {
			diffs = diffs.concat(currentPath);
		}

		return diffs;
	} else {
		const keys = unionKeys(fistObject, secondObject);

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];

			const pathKey = buildIterateArrayKey(
				fistObject,
				key,
				options?.iterateArrayKey,
				currentPath
			);
			const path = buildPath(currentPath, pathKey, Array.isArray(fistObject));
			diffs = objectDiff(get(fistObject, key), get(secondObject, key), options, path, diffs);
		}

		return diffs;
	}
};

const buildIterateArrayKey = (
	fistObject: unknown,
	key: string,
	iterateArrayKey?: string,
	currentPath?: string
) => {
	if (iterateArrayKey) {
		return Array.isArray(fistObject)
			? get(get(fistObject, key) as unknown, iterateArrayKey, key)
			: key;
	}

	return key;
};

const buildPath = (curPath: string, key: string, isArr: boolean) => {
	return curPath ? curPath + "." + (isArr ? "[" : "") + key + (isArr ? "]" : "") : key;
};

const unionKeys = (o1: unknown, o2: unknown) => {
	const keys = getKeys(o1).concat(getKeys(o2));
	const o = keys.reduce<Record<string, boolean>>(function (acc, cur) {
		acc[cur] = true;
		return acc;
	}, {});

	return Object.keys(o);
};

function getKeys(o: unknown) {
	return typeof o === "undefined" || o === null ? [] : Object.keys(o ?? {});
}
