import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {ITournament} from "data/types/entities";
import {getDashedValue} from "data/utils/helpers";
import {MatchStatus} from "views/components/match_status/match_status.component";
import {Typography} from "@mui/material";
import {SquadImage} from "views/components/squad_image/squad_image.component";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import {Exist} from "views/components/exist/exist.component";
import {Possession, TournamentStatus} from "data/enums";

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	color: ${({theme}) => theme.color.text.white};
	padding: 4px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;

	--statusWidth: 100%;
	&.home-lose .home-squad,
	&.away-lose .away-squad {
		color: rgba(255, 255, 255, 0.5);
	}

	&.active {
		background: ${({theme}) =>
			theme.color.components.horizontalSchedule.dark.backgroundSelected};
	}

	.caption {
		text-transform: uppercase;
	}

	.squad-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-width: 80px;
		width: 100%;
		color: ${({theme}) => theme.color.text.white};
		gap: 6px;

		.squad,
		.squad-points {
			font-weight: 600;
			font-size: 16px;
			line-height: 120%;
			letter-spacing: 0.02em;
		}

		.squad {
			margin-right: auto;
		}
	}

	img {
		max-width: 13px;
	}

	svg {
		width: 15px;
		padding-bottom: 3px;
		color: var(--primaryColor);
		height: auto;
	}
`;

interface IProps {
	tournament: ITournament;
	selectedTournamentId: number;
	loseClass: string;
}

export const SubheaderScheduledItem: React.FC<IProps> = observer(
	({tournament, selectedTournamentId, loseClass}) => {
		const className = tournament.id === selectedTournamentId ? "active" : "";

		if (!tournament) {
			return null;
		}

		const {id, homeSquad, awaySquad, possession, status} = tournament;
		const isPlaying = status === TournamentStatus.Playing;
		return (
			<Wrapper className={`${className} ${loseClass}`}>
				<MatchStatus tournamentId={id} small={true} />
				<div className="squad-item away-squad">
					<SquadImage squadId={awaySquad.id} />
					<Typography variant="subtitle1" className="squad">
						{awaySquad.shortName || "--"}
					</Typography>
					<Exist when={possession === Possession.AWAY && isPlaying}>
						<SportsFootballIcon />
					</Exist>

					<Typography variant="subtitle1" className="squad-points">
						{getDashedValue(awaySquad.score)}
					</Typography>
				</div>
				<div className="squad-item home-squad">
					<SquadImage squadId={homeSquad.id} />
					<Typography variant="subtitle1" className="squad">
						{homeSquad.shortName || "--"}
					</Typography>
					<Exist when={possession === Possession.HOME && isPlaying}>
						<SportsFootballIcon />
					</Exist>
					<Typography variant="subtitle1" className="squad-points">
						{getDashedValue(homeSquad.score)}
					</Typography>
				</div>
			</Wrapper>
		);
	}
);
