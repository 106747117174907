import * as React from "react";
import {makeAutoObservable, observable} from "mobx";
import {INavigateParams, ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ITournament} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {TournamentStatus} from "data/enums";
import {NavigateFunction} from "react-router-dom";
import type {Empty} from "data/types/generics";
import {getIsValueEmptyOrNull} from "data/utils/helpers";

export interface ISubHeaderController extends ViewController<INavigateParams> {
	getIsTournamentDisabled: (tournamentId: number) => boolean;
	setTournament: (event: React.SyntheticEvent, value: number) => void;

	get tournaments(): ITournament[];

	get selectedTournamentId(): number;
	getLoseClassByTournamentId: (tournamentId: number) => string;
}

@injectable()
export class SubHeaderController implements ISubHeaderController {
	@observable protected _navigate: Empty<NavigateFunction>;

	constructor(@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore) {
		makeAutoObservable(this);
	}

	get selectedTournamentId(): number {
		return this._roundsStore.selectedTournament?.id || 0;
	}

	get tournaments(): ITournament[] {
		return this._roundsStore.selectedRound?.tournaments ?? [];
	}

	public setTournament = (event: React.SyntheticEvent, value: number) => {
		const tournament = this._roundsStore.getTournamentById(value);

		if (!tournament) {
			return;
		}

		this._roundsStore.selectedTournament = tournament;
		this._navigate?.(`/fixture/${tournament.id}/game_cast`);
	};

	public getIsTournamentDisabled = (tournamentId: number) => {
		const tournament = this.tournaments.find((e) => e.id === tournamentId);
		if (!tournament) {
			return true;
		}

		return tournament.status === TournamentStatus.Scheduled;
	};

	dispose(): void {
		return;
	}

	init(param: INavigateParams): void {
		this._navigate = param.navigate;
	}

	public getLoseClassByTournamentId = (tournamentId: number) => {
		const tournament = this._roundsStore.getTournamentById(tournamentId);

		if (tournament?.status !== TournamentStatus.Complete) {
			return "";
		}

		const homeScore = tournament.homeSquad.score;
		const awayScore = tournament.awaySquad.score;

		if (getIsValueEmptyOrNull(homeScore) || getIsValueEmptyOrNull(awayScore)) {
			return "";
		}
		return Number(homeScore) > Number(awayScore) ? "away-lose" : "home-lose";
	};
}
