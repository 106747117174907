import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IGameMessageStore, IGameMessage} from "data/stores/game_message/game_message.store";
import {AlertColor} from "@mui/material/Alert/Alert";

export interface IGameMessageController extends ViewController {
	get isOpen(): boolean;
	get message(): string;
	get color(): AlertColor;
	handleClose(): void;
	showModal(payload: IGameMessage): void;
}

@injectable()
export class GameMessageController implements IGameMessageController {
	constructor(@inject(Bindings.GameMessageStore) private _gameMessageStore: IGameMessageStore) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return !!this._gameMessageStore.getState;
	}

	get message() {
		return this._gameMessageStore.getState?.message ?? "";
	}

	get color() {
		return this._gameMessageStore.getState?.color ?? "info";
	}

	handleClose() {
		this._gameMessageStore.close();
	}

	showModal(payload: IGameMessage) {
		this._gameMessageStore.showMessage(payload);
	}

	init(param: void) {
		return;
	}

	dispose() {
		return;
	}
}
