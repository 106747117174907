import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {SubHeaderFilters} from "views/components/sub_header_filters/sub_header_filters.component";
import {breakpoints} from "assets/theme/theme";
import {useViewController} from "data/services/locator";
import {ISubHeaderController} from "views/components/sub_header/subheader.controller";
import {Bindings} from "data/constants/bindings";
import {Tab, Tabs, ThemeProvider} from "@mui/material";
import {SubheaderScheduledItem} from "views/components/sub_header_scheduled_item/subheader_scheduled_item.component";
import {subHeaderTabsTheme} from "assets/theme/subTheme/subHeaderTabs.theme";
import {useNavigate} from "react-router-dom";

const Wrapper = styled.div`
	background: ${({theme}) => theme.color.components.subheader.background};
	padding: 12px 24px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 84px;
	gap: 24px;

	@media screen and (max-width: ${breakpoints.mobile}) {
		padding: 0;
		min-height: unset;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 0;
	}
`;

const FiltersBlock = styled.div`
	@media screen and (max-width: ${breakpoints.mobile}) {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		min-height: unset;
		background: ${({theme}) => theme.color.components.header.background};
	}
`;

const ItemsBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	transition: 0.25s;
	//height: 60px;
	width: 100%;
	overflow: hidden;

	@media screen and (max-width: ${breakpoints.mobile}) {
		min-height: 84px;
		background: ${({theme}) => theme.color.components.subheader.background};
		padding: 12px 6px;
		max-width: 100%;
		justify-content: center;
	}
`;

const Divider = styled.div`
	height: 100%;
	min-height: 50px;
	width: 1px;
	min-width: 1px;
	background: rgba(255, 255, 255, 0.32);
`;

export const Subheader: React.FC = observer(() => {
	const navigate = useNavigate();
	const {
		tournaments,
		selectedTournamentId,
		getIsTournamentDisabled,
		setTournament,
		getLoseClassByTournamentId,
	} = useViewController<ISubHeaderController>(Bindings.SubHeaderController, {navigate});

	return (
		<Wrapper>
			<FiltersBlock>
				<SubHeaderFilters />
			</FiltersBlock>

			<ItemsBlock>
				<ThemeProvider theme={subHeaderTabsTheme}>
					<Tabs
						value={selectedTournamentId}
						centered={false}
						scrollButtons="auto"
						onChange={setTournament}
						allowScrollButtonsMobile>
						{/*Fix when no tab selected*/}
						<Tab label="" value={0} disabled hidden />
						{tournaments.map((tournament) => (
							<Tab
								key={tournament.id}
								value={tournament.id}
								disabled={getIsTournamentDisabled(tournament.id)}
								label={
									<React.Fragment>
										<SubheaderScheduledItem
											tournament={tournament}
											selectedTournamentId={selectedTournamentId}
											loseClass={getLoseClassByTournamentId(tournament.id)}
										/>
										<Divider />
									</React.Fragment>
								}
							/>
						))}
					</Tabs>
				</ThemeProvider>
			</ItemsBlock>
		</Wrapper>
	);
});
