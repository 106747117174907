import styled from "@emotion/styled";
import backgroundDesktop from "assets/img/auth/backgroundDesktop.jpg";
import backgroundMobile from "assets/img/auth/backgroundMobile.jpg";
import {breakpoints} from "assets/theme/theme";
import {Box, Button} from "@mui/material";

export const AuthWrapper = styled.div`
	padding: 92px 18px 18px 18px;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	// 83px - header
	min-height: calc(100vh - 83px);

	background: url(${backgroundDesktop});
	background-size: cover;
	background-position: center center;

	&.full-screen {
		min-height: 100vh;
	}

	@media screen and (max-width: ${breakpoints.mobile}) {
		background: url(${backgroundMobile});
		// 79px - header
		min-height: calc(100vh - 79px);
		padding-top: 20px;

		&.full-screen {
			min-height: 100vh;
		}
	}
`;

export const AuthModalLayout = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
	max-width: 360px;
	width: 100%;

	background: ${({theme}) => theme.color.brand.neutral[0]};

	> svg {
		width: 95%;
		height: auto;
	}

	a:hover {
		text-decoration: underline;
	}

	> .error,
	form > .error {
		color: ${({theme}) => theme.color.semantic.error};
	}

	&.fixed {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.title {
		text-align: center;
	}
`;

export const ModalCloseButton = styled(Button)`
	margin-left: auto;
`;

export const PageTableRow = styled.div`
	width: 100%;
	display: grid;
	gap: 20px;
	align-items: stretch;
	grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

	&.inside {
		grid-template-columns: repeat(4, minmax(120px, 1fr));
	}

	@media screen and (max-width: 600px) {
		&.inside {
			grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
		}
	}
`;

export const PageTableColumn = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	gap: 12px;

	> div {
		width: 100%;
	}

	&.w-25 {
		grid-column: span 1;
	}

	&.w-50 {
		grid-column: span 2;
	}

	&.w-75 {
		grid-column: span 3;
	}

	@media screen and (max-width: 890px) {
		&.w-25 {
			grid-column: span 2;
		}
	}
`;

export const CollapseSectionWrapper = styled(Box)`
	width: 100%;
	background: ${({theme}) => theme.color.brand.neutral[25]};
	padding: 8px 12px;
	border-top: 1px solid ${({theme}) => theme.color.components.table.borderColor};
	border-bottom: 1px solid ${({theme}) => theme.color.components.table.borderColor};
	position: sticky;
	left: 0;

	@media screen and (max-width: 800px) {
		flex-direction: column;
		max-width: calc(100vw - 48px);
	}

	@media screen and (max-width: 600px) {
		max-width: calc(100vw - 32px);
	}
`;
