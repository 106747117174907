import React from "react";
import {observer} from "mobx-react";
import {AuthModalLayout, AuthWrapper} from "views/components/сommon/Layout";
import {ReactComponent as AuthLogo} from "assets/img/auth/logo.svg";
import {useViewController} from "data/services/locator";
import {IModalUserManagementController} from "views/components/modals/modal_user_management/modal_user_management.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {Exist} from "views/components/exist/exist.component";
import {Typography} from "@mui/material";

const SAuthModalLayout = styled(AuthModalLayout)`
	min-height: 340px;

	.text-block {
		text-align: center;
		margin: auto 0;
	}
`;

export const ModalUserManagement: React.FC = observer(() => {
	const {isDenied, isAwaiting, isSuspended, isFirstRegister, userEmail} =
		useViewController<IModalUserManagementController>(Bindings.ModalUserManagementController);
	return (
		<AuthWrapper className="full-screen">
			<SAuthModalLayout>
				<AuthLogo />

				<Exist when={isFirstRegister}>
					<Typography variant="subtitle1" className="text-block links-block">
						<span>Thanks for registering! You’ll soon be notified at:</span>
						<span> </span>
						<a href={`mailto:${userEmail}`}>{userEmail}</a>
						<span> </span>
						<span>when your account is active.</span>
					</Typography>
				</Exist>
				<Exist when={!isFirstRegister}>
					<Exist when={isAwaiting}>
						<Typography variant="subtitle1" className="text-block links-block">
							<span>
								Your credentials are still pending approval from our admin team!
								You’ll soon be notified at
							</span>
							<span> </span>
							<a href={`mailto:${userEmail}`}>{userEmail}</a>
							<span> </span>
							<span>when your account is active.</span>
						</Typography>
					</Exist>

					<Exist when={isDenied}>
						<Typography variant="subtitle1" className="text-block links-block">
							<span>
								Your access request was denied, for more info or to appeal this
								decision, please contact us at:
							</span>
							<span> </span>
							<a href="mailto:admin@cfl.ca">admin@cfl.ca</a>
						</Typography>
					</Exist>
					<Exist when={isSuspended}>
						<Typography variant="subtitle1" className="text-block links-block">
							<span>
								Your account has timed out, for more info, please contact us at:
							</span>
							<span> </span>
							<a href="mailto:admin@cfl.ca">admin@cfl.ca</a>
						</Typography>
					</Exist>
				</Exist>
			</SAuthModalLayout>
		</AuthWrapper>
	);
});
