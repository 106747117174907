import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {useLocation} from "react-router";
import {Bindings} from "data/constants/bindings";
import type {IHighlightStore} from "data/stores/highlight/highlight.store";
import {FixtureTabs} from "data/enums";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

interface ITab {
	label: FixtureTabs;
	to: string;
	tag: ConnextraType;
	isHidden?: boolean;
}

export interface IFixtureTabsController extends ViewController {
	get currentTabIndex(): number;

	get tabs(): ITab[];

	updateLocation: (location: ReturnType<typeof useLocation>) => void;
}

@injectable()
export class FixtureTabsController implements IFixtureTabsController {
	private get allTabs() {
		return [
			{
				label: FixtureTabs.GameCast,
				to: "game_cast",
				tag: ConnextraType.FIXTUREPAGE_GAMECAST,
			},
			{
				label: FixtureTabs.Team,
				to: "team",
				tag: ConnextraType.FIXTUREPAGE_TEAM,
			},
			{
				label: FixtureTabs.Offence,
				to: "offence",
				tag: ConnextraType.FIXTUREPAGE_OFFENCE,
			},
			{
				label: FixtureTabs.KickReturns,
				to: "kick_returns",
				tag: ConnextraType.FIXTUREPAGE_KICKS_RETURNS,
			},
			{
				label: FixtureTabs.Defence,
				to: "defence",
				tag: ConnextraType.FIXTUREPAGE_DEFENCE,
			},
			{
				label: FixtureTabs.PlayList,
				to: "play_list",
				tag: ConnextraType.FIXTUREPAGE_PLAYLIST,
			},
			{
				label: FixtureTabs.Scoring,
				to: "scoring",
				tag: ConnextraType.FIXTUREPAGE_SCORING,
			},
			{
				label: FixtureTabs.DriveChart,
				to: "drive_chart",
				tag: ConnextraType.FIXTUREPAGE_DRIVECHART,
				isHidden: this._roundsStore.isRoundWithPrevStats,
			},
		];
	}

	private _tabsChangeDisposer: ReturnType<typeof reaction> | null = null;

	@observable private _location: ReturnType<typeof useLocation> | null = null;

	constructor(
		@inject(Bindings.HighlightStore) private _highlightStore: IHighlightStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get tabs() {
		return this.allTabs.filter((it) => !it.isHidden);
	}

	get currentTabIndex() {
		if (!this._location) {
			return -1;
		}

		return this.tabs.findIndex((it) => this._location?.pathname.includes(it.to));
	}

	get currentTab() {
		return this.allTabs[this.currentTabIndex];
	}

	updateLocation(location: ReturnType<typeof useLocation>) {
		this._location = location;
	}

	dispose(): void {
		this._tabsChangeDisposer?.();
	}

	init(): void {
		this._tabsChangeDisposer = reaction(
			() => this.currentTabIndex,
			() => {
				this._highlightStore.calculateDiffForTab(this.currentTab?.label);
				this.createTag();
			}
		);
	}

	createTag = () => {
		const index = this.currentTabIndex;
		const tab = this.tabs[index];
		if (!tab) {
			return;
		}
		createConnextraScriptTag(tab.tag);
	};
}
