import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {getImageFromBackend} from "data/utils/helpers";
import {useViewController} from "data/services/locator";
import {ISquadImageController} from "views/components/squad_image/squad_image.controller";
import {Bindings} from "data/constants/bindings";
import logo from "assets/img/icons/logo.svg";

export interface IProps {
	squadId: number;
	shortName?: string;
}

export const SquadImage: React.FC<IProps> = observer(({squadId, shortName}) => {
	const {squad, squadImagePath, setSquadId} = useViewController<ISquadImageController>(
		Bindings.SquadImageController,
		{squadId}
	);

	useEffect(() => {
		setSquadId(squadId);
	}, [squadId, setSquadId]);

	const alt = String(shortName || squad?.shortName || squad?.name || squadId);

	const onError = (e: React.UIEvent<HTMLImageElement>) => {
		e.currentTarget.src = logo;
		e.stopPropagation();
		e.preventDefault();
		return false;
	};

	if (shortName) {
		return <img onError={onError} src={getImageFromBackend(shortName)} alt={alt} />;
	}

	return <img onError={onError} src={squadImagePath} alt={alt} />;
});
