import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
} from "data/stores/static_content/static_content.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IRound} from "data/stores/rounds/rounds.store";
import {Language} from "data/enums";
import {ISquad} from "data/types/entities";
import {IMatchStatsPlayerSquads, IMatchStatPlays} from "data/types/matchStats";
import {IChecksums} from "data/stores/checksums/checksums.store";
import {ISetting} from "data/stores/settings/settings.store";
import {IMatchClock} from "data/stores/clock/clock.store";

export interface IJSONProvider {
	settings(): Promise<AxiosResponse<ISetting[]>>;
	clock(): Promise<AxiosResponse<IMatchClock[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;
	countries(): Promise<AxiosResponse<ICountry[]>>;
	roundsByYear(year: number): Promise<AxiosResponse<IRound[]>>;
	squads(): Promise<AxiosResponse<ISquad[]>>;
	matchStats(matchId: number, year: number): Promise<AxiosResponse<IMatchStatsPlayerSquads[]>>;
	matchStatsPlays(matchId: number, year: number): Promise<AxiosResponse<IMatchStatPlays[]>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;
	helpSections(locale: string): Promise<AxiosResponse<ISections>>;
	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	settings = () => this._jsonClient.get<ISetting[]>("settings.json");
	clock = () => this._jsonClient.get<IMatchClock[]>("clock.json");
	// clock = () => {
	// 	const defautlClock = {
	// 		clock: "00:15:00",
	// 		lastUpdatedUtc: DateTime.now().toISO(),
	// 		isRunning: true,
	// 	};
	//
	// 	if (!get(window, "clock", null)) {
	// 		Object.assign(window, {
	// 			clock: defautlClock,
	// 		});
	// 	}
	// 	return createJsonSuccessResponse<Record<string, IMatchClock>>({
	// 		"1680": {
	// 			...get(window, "clock", defautlClock),
	// 			lastUpdatedUtc: DateTime.now().toISO(),
	// 		},
	// 	});
	// };

	checksums = () => this._jsonClient.get<IChecksums>("checksums.json");
	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	roundsByYear = (year: number) => {
		return this._jsonClient.get<IRound[]>(`${year}/rounds.json`);
	};
	squads = () => this._jsonClient.get<ISquad[]>("squads.json");

	matchStats = (matchId: number, year: number) =>
		this._jsonClient.get<IMatchStatsPlayerSquads[]>(`${year}/stats/${matchId}.json`);
	matchStatsPlays = (matchId: number, year: number) =>
		this._jsonClient.get<IMatchStatPlays[]>(`${year}/stats/${matchId}_plays.json`);
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`locale/${locale}.json`);

	helpCategories = (locale: Language) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json?per_page=200`);
	helpSections = (locale: Language) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json?per_page=200`);
	helpArticles = (locale: Language) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json?per_page=200`);
}
