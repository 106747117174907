import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";

export interface IHeaderAccountController extends ViewController {
	get isAuthorized(): boolean;

	logout: () => void;
}

@injectable()
export class HeaderAccountController implements IHeaderAccountController {
	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	public logout = () => {
		void this._userStore.logout();
	};
}
