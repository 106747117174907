import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {keyBy} from "lodash";

export interface IMatchClock {
	tournamentId?: number;
	clock: string | null;
	lastUpdatedUtc: string | null;
	isRunning: boolean | null;
}
export interface IClockStore {
	get matchClocks(): Record<number, IMatchClock>;
	fetch(): Promise<void>;
	updateMatchClock(matchId: number, matchClock: IMatchClock): void;
}

@injectable()
export class ClockStore implements IClockStore {
	@observable _matchClocks: Record<string, IMatchClock> = {};
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	get matchClocks(): Record<number, IMatchClock> {
		return this._matchClocks;
	}

	async fetch() {
		const response = await this._jsonProvider.clock();
		runInAction(() => {
			this._matchClocks = keyBy(response.data, "tournamentId");
		});
	}

	updateMatchClock(matchId: number, matchClock: IMatchClock) {
		this._matchClocks = {
			...this._matchClocks,
			[matchId]: matchClock,
		};
	}
}
