import React from "react";
import {observer} from "mobx-react";
import {Button, Modal, Typography} from "@mui/material";
import {AuthModalLayout, ModalCloseButton} from "views/components/сommon/Layout";
import {useViewController} from "data/services/locator";
import {IModalSuccessController} from "views/components/modals/modal_success/modal_success.controller";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import styled from "@emotion/styled";
import {getDashedValue} from "data/utils/helpers";

const Title = styled(Typography)`
	max-width: 230px;
	text-align: center;
`;

const Text = styled(Typography)`
	max-width: 256px;
	text-align: center;
	color: ${({theme}) => theme.color.brand.neutral[500]};
`;

const ConfirmButton = styled(Button)`
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
`;

export const ModalSuccess: React.FC = observer(() => {
	const {isModalOpen, modalData, closeModal} = useViewController<IModalSuccessController>(
		Bindings.ModalSuccessController
	);
	return (
		<Modal open={isModalOpen}>
			<AuthModalLayout className="fixed">
				<ModalCloseButton color="adornment" onClick={closeModal}>
					<IconClose />
				</ModalCloseButton>

				<Title variant="subtitle1">{getDashedValue(modalData.title)}</Title>

				<Text variant="subtitle1">{getDashedValue(modalData.message)}</Text>

				<ConfirmButton fullWidth onClick={closeModal}>
					{modalData.buttonText ?? "OK"}
				</ConfirmButton>
			</AuthModalLayout>
		</Modal>
	);
});
